import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  Input,
  NgZone,
  QueryList,
  ViewEncapsulation,
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { matLegacyFormFieldAnimations as matFormFieldAnimations } from '@angular/material/legacy-form-field';
import { startWith } from 'rxjs/operators';
import { IkError } from './error';
import { IkFormFieldControl } from './form-field-control';
import { IkHint } from './hint';
import { IkLabel } from './label';
// eslint-disable  @angular-eslint/no-output-rename, @angular-eslint/no-input-rename

let nextUniqueId = 0;

@Component({
  selector: 'ik-form-field',
  templateUrl: './form-field.component.html',
  styleUrls: ['./form-field.component.scss'],
  animations: [matFormFieldAnimations.transitionMessages],
  host: {
    class: 'ik-form-field',
    '[class.ik-form-field-invalid]': '_control.errorState',
    '[class.ik-form-field-disabled]': '_control.disabled',
    '[class.ik-form-field-autofilled]': '_control.autofilled',
    '[class.ik-focused]': '_control.focused',
    '[class.ng-untouched]': '_shouldForward("untouched")',
    '[class.ng-touched]': '_shouldForward("touched")',
    '[class.ng-pristine]': '_shouldForward("pristine")',
    '[class.ng-dirty]': '_shouldForward("dirty")',
    '[class.ng-valid]': '_shouldForward("valid")',
    '[class.ng-invalid]': '_shouldForward("invalid")',
    '[class.ng-pending]': '_shouldForward("pending")',
  },
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'formField',
})
export class FormFieldComponent implements AfterContentInit, AfterContentChecked, AfterViewInit {
  @Input()
  hideRequiredMarker: boolean;
  // Unique id for the hint label.
  _hintLabelId = `ik-hint-${nextUniqueId++}`;
  // Unique id for the internal form field label.
  _labelId = `ik-form-field-label-${nextUniqueId++}`;
  @ContentChildren(IkError)
  _errorChildren: QueryList<IkError>;
  @ContentChildren(IkHint)
  _hintChildren: QueryList<IkHint>;
  @ContentChild(IkFormFieldControl)
  _control: IkFormFieldControl<any>;
  @ContentChild(IkLabel)
  _labelChild: IkLabel;
  _subscriptAnimationState = '';

  constructor(
    public _elementRef: ElementRef,
    private _changeDetectorRef: ChangeDetectorRef,
    private _ngZone?: NgZone
  ) {}

  private _hintLabel = '';

  @Input()
  get hintLabel(): string {
    return this._hintLabel;
  }

  set hintLabel(value: string) {
    this._hintLabel = value;
    this._processHints();
  }

  _hasLabel() {
    return !!this._labelChild;
  }

  _getDisplayedMessages(): 'error' | 'hint' {
    return this._errorChildren && this._errorChildren.length > 0 && this._control.errorState ? 'error' : 'hint';
  }

  public ngAfterViewInit(): void {
    this._subscriptAnimationState = 'enter';
    this._changeDetectorRef.detectChanges();
  }

  _shouldForward(prop: keyof NgControl): boolean {
    const ngControl = this._control ? this._control.ngControl : null;
    return ngControl && ngControl[prop];
  }

  public ngAfterContentChecked(): void {
    this._validateControlChild();
  }

  ngAfterContentInit() {
    this._validateControlChild();

    const control = this._control;

    if (control.controlType) {
      this._elementRef.nativeElement.classList.add(`ik-form-field-type-${control.controlType}`);
    }

    // Subscribe to changes in the child control state in order to update the form field UI.
    control.stateChanges.pipe(startWith<void, any>(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });

    // Run change detection if the value changes.
    if (control.ngControl && control.ngControl.valueChanges) {
      control.ngControl.valueChanges.subscribe(() => this._changeDetectorRef.markForCheck());
    }

    // Re-validate when the number of hints changes.
    this._hintChildren.changes.pipe(startWith<void, any>(null)).subscribe(() => {
      this._processHints();
      this._changeDetectorRef.markForCheck();
    });

    // Update the aria-described by when the number of errors changes.
    this._errorChildren.changes.pipe(startWith<void, any>(null)).subscribe(() => {
      this._syncDescribedByIds();
      this._changeDetectorRef.markForCheck();
    });
  }

  protected _validateControlChild() {
    if (!this._control) {
      return Error('ik-form-field must contain a IkFormFieldControl.');
    }
  }

  // eslint-disable
  private _syncDescribedByIds() {
    if (this._control) {
      let ids: string[] = [];

      if (this._getDisplayedMessages() === 'hint') {
        const startHint = this._hintChildren ? this._hintChildren.find((hint) => hint.align === 'start') : null;
        const endHint = this._hintChildren ? this._hintChildren.find((hint) => hint.align === 'end') : null;

        if (startHint) {
          ids.push(startHint.id);
        } else if (this._hintLabel) {
          ids.push(this._hintLabelId);
        }

        if (endHint) {
          ids.push(endHint.id);
        }
      } else if (this._errorChildren) {
        ids = this._errorChildren.map((error) => error.id);
      }

      this._control.setDescribedByIds(ids);
    }
  }

  private _processHints() {
    this._validateHints();
    this._syncDescribedByIds();
  }

  private _validateHints() {
    if (this._hintChildren) {
      let startHint: IkHint;
      let endHint: IkHint;
      this._hintChildren.forEach((hint: IkHint) => {
        if (hint.align === 'start') {
          if (startHint || this.hintLabel) {
            return Error(`A hint was already declared for 'align="start"'.`);
          }
          startHint = hint;
        } else if (hint.align === 'end') {
          if (endHint) {
            return Error(`A hint was already declared for 'align="end"'.`);
          }
          endHint = hint;
        }
      });
    }
  }
}
