import { Injectable, Injector, StaticProvider } from '@angular/core';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { DATA_TOKEN } from '../helpers/tokens';

@Injectable({
  providedIn: 'root',
})
export class OverlayService {
  private overlayRef: OverlayRef;

  constructor(private overlay: Overlay, private injector: Injector) {}

  public open(component: any, data: any): void {
    // Ensure we only create the overlay if one doesn't already exist
    if (!this.overlayRef || !this.overlayRef.hasAttached()) {
      this.overlayRef = this.overlay.create();

      const providers: StaticProvider[] = [{ provide: DATA_TOKEN, useValue: data }];
      const componentInjector = Injector.create({ providers, parent: this.injector });

      const componentPortal = new ComponentPortal(component, null, componentInjector);
      this.overlayRef.attach(componentPortal);
    }
  }

  public close(): void {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  public isOpen(): boolean {
    return this.overlayRef ? this.overlayRef.hasAttached() : false;
  }
}
