import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { select, Store } from '@ngrx/store';
import moment from 'moment';
import { combineLatest, of, throwError } from 'rxjs';
import { catchError, delay, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { DesktopApi } from '../helpers/desktop.api';
import { CloudApi } from '@app/helpers/cloud.api';
import { localTestsTableSelectors } from '../root-store/local-tests-store/local-tests-table.ngrx';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { Test } from '../../typings/test.interface';
import { MainEquipmentQuery } from '../state/main-equipment.query';
import { ActiveEquipmentStore } from '../state/active-equipment.store';
import { LocalTestStore } from '../root-store/local-tests-store/local-tests.store';
import { AuthQuery } from '../auth/state/auth.query';
import { TranslateService } from '@ngx-translate/core';

interface EquipmentInfo {
  equipmentModel: string;
  connectionType: string;
  address: string;
}

@Injectable({
  providedIn: 'root',
})
export class LocalTestsService {
  private isoDateFormat = 'YYYY-MM-DD';
  private testPerPage = 25;

  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private store: Store<any>,
    private mainEquipmentQuery: MainEquipmentQuery,
    private activeEquipmentStore: ActiveEquipmentStore,
    private snackbar: MatSnackBar,
    private localTestStore: LocalTestStore,
    private authQuery: AuthQuery,
    private translate: TranslateService
  ) {}

  private getMainEquipmentInfo(): EquipmentInfo {
    const mainEquipment = this.mainEquipmentQuery.getValue();
    return {
      equipmentModel: mainEquipment.info.model,
      connectionType: mainEquipment.info.connectionType,
      address: mainEquipment.info.address,
    };
  }

  private getActiveEquipmentInfoByRow(model: string, number: string): EquipmentInfo {
    const activeEquipment = this.activeEquipmentStore.getValue().data.find((equipment) => {
      return equipment.info.model === model && equipment.info.model_number === number;
    });
    if (activeEquipment) {
      return {
        equipmentModel: activeEquipment.info.model,
        connectionType: activeEquipment.info.connectionType,
        address: activeEquipment.info.address,
      };
    } else {
      return this.getMainEquipmentInfo();
    }
  }

  remove(id) {
    return this.localTestStore.deleteTest(id);
  }

  removeOnEquipment(row, id, testName) {
    const snackbar = this.snackbar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('localTests.removingTestFile'),
      duration: 0,
    });
    return this.deleteTestOnEquipment(row, id).pipe(
      tap(
        (value) => {
          if (value) {
            this.snackbar.openFromComponent(CustomSnackbarComponent, {
              data: this.translate.instant('localTests.testFileRemoved'),
              duration: 5000,
            });
          }
        },
        (err) => {
          this.snackbar.openFromComponent(CustomSnackbarComponent, {
            data: this.translate.instant('localTests.couldNotDeleteTest', { testName: testName }),
            duration: 5000,
          });
        }
      ),
      finalize(() => snackbar.dismiss())
    );
  }

  deleteTestOnEquipment(row, id) {
    return this.http
      .post(`${DesktopApi.equipmentTests}/delete`, {
        id: id,
        equipmentInfo: this.getActiveEquipmentInfoByRow(row.equipment_model, row.equipment_number),
      })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  convertTestFileApi(formData: FormData) {
    return this.http.post(`${this.cloudApi.testFile}`, formData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  getFromEquipment() {
    return combineLatest([
      this.store.pipe(select(localTestsTableSelectors.selectPage)),
      this.store.pipe(select(localTestsTableSelectors.selectFilters)),
      this.store.pipe(select(localTestsTableSelectors.selectSort)),
    ]).pipe(
      take(1),
      switchMap(async ([page, filters, order = '-id']) => {
        const testFiles = await this.getTestsFromActiveInstruments();
        return [page, filters, order, testFiles];
      }),
      switchMap(([page, filtersUnknown, orderUnknown, testFiles]) => {
        const filters: any = filtersUnknown;
        const order: any = orderUnknown;
        const orderReverse = order.startsWith('-');
        const orderKey = orderReverse ? order.slice(1) : order;

        return of(testFiles).pipe(
          map((data: any[]) => {
            if (orderReverse) {
              data.sort((a, b) => {
                if (typeof a[orderKey] === 'string') {
                  return b[orderKey].localeCompare(a[orderKey]);
                }
                return b[orderKey] > a[orderKey] ? 1 : -1;
              });
            } else {
              data.sort((a, b) => {
                if (typeof a[orderKey] === 'string') {
                  return a[orderKey].localeCompare(b[orderKey]);
                }
                return a[orderKey] > b[orderKey] ? 1 : -1;
              });
            }

            if (!filters) {
              return data;
            }

            data = this.filterByTestName(filters, data);
            data = this.filterByCreator(filters, data);
            data = this.filterByEquipmentModel(filters, data);

            if (filters.created && filters.created.from && filters.created.to) {
              const isoDateFormat = 'YYYY-MM-DD';
              const created_from = moment(filters.created.from).format(isoDateFormat);
              const created_to = moment(filters.created.to).format(isoDateFormat);
              data = this.filterByDates(created_from, created_to, data);
            }

            return data;
          }),
          map((data) => ({
            data,
          }))
        );
      })
    );
  }

  async getTestsFromActiveInstruments() {
    let arr = [];
    const data = this.activeEquipmentStore.getValue().data;
    for await (let equipment of data) {
      if (!equipment.info.model.startsWith('Multiplexer')) {
        const equipmentInfo = {
          equipmentModel: equipment.info.model,
          connectionType: equipment.info.connectionType,
          address: equipment.info.address,
        };
        arr = arr.concat(await this.getTestsFromEquipment(equipmentInfo).toPromise());
      }
    }
    return arr;
  }

  // get all test data from instrument.
  getTestsFromEquipmentById(id: any, model: string, number: string) {
    return this.http.post(`${DesktopApi.equipmentTests}/details`, {
      id: id,
      equipmentInfo: this.getActiveEquipmentInfoByRow(model, number),
    });
  }

  // Using in test table to get all test data.
  getAllTests() {
    return combineLatest([
      this.store.pipe(select(localTestsTableSelectors.selectFilters)),
      this.mainEquipmentQuery.info$,
    ]).pipe(
      take(1),
      switchMap(([filters, isActiveEquipment]) => {
        if (isActiveEquipment) {
          if (filters.location === 'cloud') {
            return this.getTests();
          } else if (filters.location === 'instrument') {
            return this.getFromEquipment();
          }
          return this.concatTests();
        } else {
          return this.getTests();
        }
      })
    );
  }

  // merge Tests data from local and instrument.
  concatTests() {
    return combineLatest([this.getFromEquipment(), this.getTests()]).pipe(
      take(1),
      map(([instrumentTests, cloudTests]) => {
        const tests = cloudTests;
        if (instrumentTests['data']) {
          tests.data = this.mergeTests(cloudTests.data, instrumentTests['data']);
        }
        return tests;
      })
    );
  }

  // merge Tests data from local and instrument.
  mergeTests(cloudTests, instrumentTests) {
    const tests = instrumentTests.reduce((obj, test) => {
      if (!obj[test.id]) {
        obj[test.id] = {
          test_file: [],
          test_name: [],
        };
      }
      obj[test.id].test_name.push(test.test_name);
      obj[test.id].test_file.push(test.test_file);
      return obj;
    }, {});

    return cloudTests.map((element) => {
      if (tests[element.id]) {
        element.isInstrumentTest = true;
        element.instrumentTests = tests[element.id];
      }
      return element;
    });
  }

  // Using in test table to get all test data.
  getTests() {
    return combineLatest([
      this.store.pipe(select(localTestsTableSelectors.selectPage)),
      this.store.pipe(select(localTestsTableSelectors.selectFilters)),
      this.store.pipe(select(localTestsTableSelectors.selectSort)),
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters).reduce(function (result: any, key) {
          const isoDateFormat = 'YYYY-MM-DD';
          if (key === 'created') {
            if (filters[key].from) {
              result.created_from = moment(filters[key].from).format(isoDateFormat);
            }
            if (filters[key].to) {
              result.created_to = moment(filters[key].to).format(isoDateFormat);
            }
            return result;
          }
          if (key !== 'location') {
            const value = filters[key] && filters[key].toString().trim();
            if (value) {
              result[key] = value;
            }
          }
          return result;
        }, {});
        const params = {
          filter: preparedFilters,
          page: page,
          order: order || '-id',
        };
        return this.getTestsFromLocalStore(params);
      })
    );
  }

  // Using in test table to get all test data.
  getTestsFromLocalStore(params) {
    let localTest = this.localTestStore.getLocalTests();
    const total = localTest.length;

    localTest = localTest.sort(dynamicSort(params.order));
    localTest = filterTest(localTest, params.filter);

    if (params.filter && params.filter.created_from && params.filter.created_to) {
      localTest = this.filterByDates(params.filter.created_from, params.filter.created_to, localTest);
    }

    localTest = paginate(localTest, this.testPerPage, params.page);

    let localTestData = {
      data: localTest,
      meta: {
        pagination: {
          per_page: this.testPerPage,
          page: params.page,
          total_count: total,
          limit_value: this.testPerPage,
        },
      },
    };

    return of(localTestData).pipe(
      map((res: any) => {
        let newData = res.data.map((test: any) => {
          let newTest = Object.assign({}, test);
          newTest.isLocalTest = true;
          newTest.isInstrumentTest = false;

          if (newTest?.steps_attributes) {
            newTest.steps_count = newTest.steps_attributes ? newTest.steps_attributes.length : 0;
            newTest.steps_attributes = newTest.steps_attributes.map((step) => {
              let testData = { ...step, ...step.parameters };

              return testData;
            });
          }

          return newTest;
        });

        res.data = [].concat(newData);
        return res;
      })
    );
  }

  public uploadToEquipment(test: any) {
    const row = JSON.parse(JSON.stringify(test));
    row.steps_attributes.map((step) => {
      if (step.step_type.toUpperCase() === 'GND') {
        if (step.scanner_channel == 0 || step.parameters.scanner_channel == 0) {
          if (step.hasOwnProperty('scanner_channel')) {
            step.scanner_channel = '00';
          }
          if (step.hasOwnProperty('parameters')) {
            step.parameters.scanner_channel = '00';
          }
        }
      }
    });
    return this.http.post(`${DesktopApi.equipmentTests}/create`, {
      fileName: row.test_name,
      steps: row.steps_attributes.map((step) => ({ ...step, ...step.parameters })),
      equipmentInfo: this.getActiveEquipmentInfoByRow(row.equipment_model, row.equipment_number),
    });
  }

  getId() {
    this.sleep(15);
    return new Date().valueOf();
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  // get test file by id.
  public getTest(id: any) {
    this.localTestStore.getTestById(id).pipe(
      map((res) => {
        return {
          ...res,
          steps_attributes: res.steps_attributes.map((step) => {
            return { ...step, ...step.parameters };
          }),
        };
      })
    );

    return this.localTestStore.getTestById(id).pipe(
      map((res) => {
        return {
          ...res,
          steps_attributes: res.steps_attributes.map((step) => {
            let testData = { ...step, ...step.parameters };
            return testData;
          }),
        };
      })
    );
  }

  // Create Local Test
  public createTest(data: any) {
    const user = this.authQuery.getValue().user;
    return this.localTestStore.createTest(data, user);
  }

  // Update Local Test
  public updateTest(id: number, data: any) {
    return this.localTestStore.updateTest(id, data);
  }

  // Generate test file and save the file on electron service.
  public generateTestFile(testData: any) {
    return this.http.post(`${DesktopApi.generateTestFile}`, testData).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  // prepare the test data object before save.
  public buildCreateTestData(data: any) {
    const user = this.authQuery.getValue().user;
    return this.localTestStore.buildCreateTestData(data, user);
  }

  // prepare the test data object before save.
  public buildUpdateTestData(data: any) {
    return this.localTestStore.buildUpdateTestData(data);
  }

  public getDutModelsFromTest(model: any) {
    // find the live test file and deleted test file.
    let params: any = { model };
    return this.localTestStore.getTestByDutModel(params);
  }

  private filterByDates(created_from, created_to, data: any[]) {
    if (created_from && created_to) {
      var utcDateStart = moment(created_from).utc();
      utcDateStart.set({
        hour: 0,
        minute: 0,
        second: 0,
        millisecond: 0,
      });

      var utcDateEnd = moment.utc(created_to);
      utcDateEnd.set({
        hour: 23,
        minute: 59,
        second: 59,
        millisecond: 999,
      });

      data = data.filter(function (report) {
        var date = moment(report.created_at).utc();
        return date.isBetween(utcDateStart, utcDateEnd);
      });
    }
    return data;
  }

  private filterByEquipmentModel(filters, data: any[]) {
    if (filters.equipment_model) {
      data = data.filter((test) => test.equipment_model.toLowerCase().includes(filters.equipment_model.toLowerCase()));
    }
    return data;
  }

  private filterByCreator(filters, data: any[]) {
    if (filters.created_by_id) {
      data = data.filter((test) => test.created_by && test.created_by.id === filters.created_by_id);
    }
    return data;
  }

  private filterByTestName(filters, data: any[]) {
    if (filters.test_name) {
      data = data.filter((test) => test.test_name.toLowerCase().includes(filters.test_name.toLowerCase()));
    }
    return data;
  }

  private getTestsFromEquipment(equipmentInfo) {
    return this.http
      .post(`${DesktopApi.equipmentTests}`, {
        equipmentInfo: equipmentInfo,
      })
      .pipe(
        map((res: any) => {
          res.map((test: any) => {
            test.isCloudTest = false;
            test.isLocalTest = true;
            test.isInstrumentTest = true;
            test.instrumentTests = {
              test_file: [test.test_file],
              test_name: [test.test_name],
            };
            return test;
          });
          return res;
        })
      );
  }
}

// Global function use in the class.
function paginate(array, page_size, page_number) {
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}

function getFullName(user) {
  if (user) {
    return user?.first_name + user?.last_name;
  } else {
    return '';
  }
}

function dynamicSort(property) {
  var sortOrder = 1;
  if (property[0] === '-') {
    sortOrder = -1;
    property = property.substr(1);
  }

  if (property === 'created_by_id') {
    return function (a, b) {
      var result =
        getFullName(a.created_by) < getFullName(b.created_by)
          ? -1
          : getFullName(a.created_by) > getFullName(b.created_by)
          ? 1
          : 0;
      return result * sortOrder;
    };
  } else if (property === 'steps_count') {
    return function (a, b) {
      var result =
        a.steps_attributes.length < b.steps_attributes.length
          ? -1
          : a.steps_attributes.length > b.steps_attributes.length
          ? 1
          : 0;
      return result * sortOrder;
    };
  } else {
    return function (a, b) {
      var result = a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
      return result * sortOrder;
    };
  }
}

function filterTest(localTest, params) {
  localTest = localTest.filter(function (item) {
    for (var key in params) {
      if (key !== 'created_by_id' && key !== 'created_from' && key !== 'created_to') {
        if (item[key] === undefined || item[key] != params[key]) {
          return false;
        }
      } else if (key === 'created_by_id') {
        if (item?.created_by?.id.toString() != params[key]) {
          return false;
        }
      }
    }

    return true;
  });

  return localTest;
}
