import { OverlayModule } from '@angular/cdk/overlay';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';

import {
  MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS,
  MatLegacySnackBarModule as MatSnackBarModule,
} from '@angular/material/legacy-snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChartBar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppConfig } from '@ik/shared-data/app.config';
import { BaseApi } from '@ik/shared-data/base.api';
import { BaseAuthService } from '@ik/shared-data/base-services/base-auth.service';
import { BaseReportsService } from '@ik/shared-data/base-services/base-reports.service';

import { CloudApi } from '@app/helpers/cloud.api';
import { AuthService } from './auth/state/auth.service';
import { ReportsService } from './services/reports.service';

import { AppComponent } from './app.component';
import { HomeComponent } from './containers/home/home.component';
import { RootStoreModule } from './root-store';
import { AuthInterceptor } from './services/auth.interceptor';
import { UpdateModalComponent } from './shared/update-modal/update-modal.component';
import { CustomSnackbarComponent } from './shared/custom-snackbar/custom-snackbar.component';
import { ErrorDialogComponent } from './shared/error-dialog/error-dialog.component';
import { IkButtonModule } from './ui/button/ik-button.module';
import { FooterComponent } from './ui/footer/footer.component';
// eslint-disable-next-line
import { HeaderEquipmentIndicatorComponent } from './ui/header-equipment-indicator/header-equipment-indicator.component';
import { HeaderComponent } from './ui/header/header.component';
import { UserPanelComponent } from './ui/header/user-panel/user-panel.component';
import { IconModule } from './ui/icon/icon.module';
import { NavigationOverlayComponent } from './ui/navigation-overlay/navigation-overlay.component';
import { OnboardingModule } from './ui/onboarding/onboarding.module';
import { PermissionModule } from './ui/permission/permission.module';
import { CompanyInactiveComponent } from './company-inactive/company-inactive.component';
import { ChangeLogModule } from './ui/change-log/change-log.module';
import { MarkdownModule, MarkedOptions, MarkedRenderer } from 'ngx-markdown';
import { AlertOverlayComponent } from './ui/alert-overlay/alert-overlay.component';
import { LanguageSelectorModule } from './ui/language-selector/language-selector.module';

import 'prismjs';
import 'prismjs/components/prism-typescript.min.js';
import 'prismjs/plugins/line-numbers/prism-line-numbers.js';
import 'prismjs/plugins/line-highlight/prism-line-highlight.js';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function markedOptions(): MarkedOptions {
  const renderer = new MarkedRenderer();

  renderer.blockquote = (text: string) => {
    return '<blockquote class="blockquote"><p>' + text + '</p></blockquote>';
  };

  return { renderer };
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    UserPanelComponent,
    NavigationOverlayComponent,
    UpdateModalComponent,
    CustomSnackbarComponent,
    HeaderEquipmentIndicatorComponent,
    CompanyInactiveComponent,
    ErrorDialogComponent,
    AlertOverlayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RootStoreModule,
    HttpClientModule,
    AppRoutingModule,
    MatDialogModule,
    IconModule,
    OverlayModule,
    IkButtonModule,
    MatSnackBarModule,
    MatSlideToggleModule,
    PermissionModule,
    ChangeLogModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    NgbTooltipModule,
    LanguageSelectorModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    OnboardingModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useFactory: markedOptions,
      },
      sanitize: SecurityContext.HTML,
    }),
  ],
  exports: [MatSlideToggleModule],
  providers: [
    AppConfig,
    //Whenever `BaseApi` is imported, `CloudApi` will be injected.
    { provide: BaseApi, useClass: CloudApi },
    //Whenever `BaseAuthService` is imported, `AuthService` will be injected.
    { provide: BaseAuthService, useClass: AuthService },
    //Whenever `BaseReportsService` is imported, `ReportsService` will be injected.
    { provide: BaseReportsService, useClass: ReportsService },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        horizontalPosition: 'right',
        verticalPosition: 'top',
      },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
    // Add an icon to the library for convenient access in other components
    library.add(faChartBar);
    library.add(faTimes);
  }
}
