import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdminUsersTableEffects } from './admin-users-table.effects';
import { adminUsersTableReducer } from './admin-users-table.ngrx';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('admin-users', {
      AdminUsersTable: adminUsersTableReducer
    }),
    EffectsModule.forFeature([AdminUsersTableEffects])
  ],
  providers: [AdminUsersTableEffects]
})
export class AdminUsersStoreModule {}
