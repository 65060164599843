import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';
import { selectPersistStateInit } from '@datorama/akita';
import { AppConfig } from '@ik/shared-data/app.config';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  role$ = combineLatest([this.authQuery.user$, this.authQuery.loggedIn$, selectPersistStateInit()]).pipe(
    map(([user, loggedIn]) => {
      return user && loggedIn ? user.role : 'Guest';
    })
  );

  constructor(
    private appConfig: AppConfig,
    private store: Store<any>,
    private authQuery: AuthQuery,
  ) { }

  checkPermission(only, except): Observable<boolean> {
    return this.role$.pipe(
      map(userRole => {
        return (only && only.includes(userRole)) || (except && except.length && !except.includes(userRole));
      })
    );
  }
}
