<div
  #origin="cdkOverlayOrigin"
  #trigger
  (click)="toggle()"
  aria-hidden="true"
  cdk-overlay-origin
  class="ik-select-trigger"
>
  <div class="ik-select-value">
    <div [ngSwitch]="empty" class="ik-select-value">
      <span *ngSwitchCase="true" class="ik-select-placeholder">{{ placeholder || '\u00A0' }}</span>
      <span *ngSwitchCase="false" [ngSwitch]="!!customTrigger" class="ik-select-value-text">
        <span *ngSwitchDefault>{{ triggerValue || '\u00A0' }}</span>
        <ng-content *ngSwitchCase="true" select="ik-select-trigger"></ng-content>
      </span>
    </div>
  </div>

  <div class="ik-select-arrow-wrapper">
    <div class="ik-select-arrow"></div>
  </div>
</div>

<ng-template
  (backdropClick)="close()"
  (detach)="close()"
  [cdkConnectedOverlayMinWidth]="_triggerRect?.width"
  [cdkConnectedOverlayOffsetY]="3"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  cdk-connected-overlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
>
  <div
    #panel
    (@transformPanel.done)="_panelDoneAnimatingStream.next($event.toState)"
    (keydown)="_handleKeydown($event)"
    [@transformPanel]="multiple ? 'showing-multiple' : 'showing'"
    [ngClass]="panelClass"
    [style.font-size.px]="_triggerFontSize"
    class="ik-select-panel"
  >
    <ng-content></ng-content>
  </div>
</ng-template>
