import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ik-navigation-overlay',
  templateUrl: './navigation-overlay.component.html',
  styleUrls: ['./navigation-overlay.component.scss']
})
export class NavigationOverlayComponent implements OnInit {
  @Output()
  close = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}
}
