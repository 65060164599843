import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AdminCompaniesService } from '../../services/admin-companies.service';
import { adminCompaniesTable } from './admin-companies.ngrx';

@Injectable()
export class AdminCompaniesTableEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCompaniesTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.adminCompaniesService.getList()),
      map(res => {
        return new adminCompaniesTable.actions.LoadDataSuccess(res);
      })
    )
  );

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCompaniesTable.actionTypes.changeSort),
      map(() => {
        return new adminCompaniesTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCompaniesTable.actionTypes.changePage),
      map(() => {
        return new adminCompaniesTable.actions.LoadData();
      })
    )
  );

  filtersChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminCompaniesTable.actionTypes.changeFilters),
      map(() => {
        return new adminCompaniesTable.actions.LoadData();
      })
    )
  );

  constructor(private actions$: Actions, private adminCompaniesService: AdminCompaniesService) {}
}
