import { Component } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ik-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss'],
})
export class LanguageSelectorComponent {
  private storageKey = 'language';

  public languages = [
    {
      value: 'en-US',
      label: 'English',
    },
    {
      value: 'zh-TW',
      label: 'Traditional Chinese',
    },
    {
      value: 'zh-CN',
      label: 'Simplified Chinese',
    },
  ];

  selectedLanguage = this.languages.find((language) => language.value === localStorage.getItem(this.storageKey))
    ? localStorage.getItem(this.storageKey)
    : 'en-US';

  constructor(private translateService: TranslateService) {
    this.translateService.use(this.selectedLanguage);
  }

  onLanguageChange(event: MatSelectChange) {
    localStorage.setItem(this.storageKey, event.value);
    this.translateService.use(event.value);
  }
}
