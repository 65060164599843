<header class="dialog-header">
  <h2 class="dialog-header-title">{{ 'common.form.newVersionAvailable' | translate }}</h2>
  <a (click)="cancel($event)" class="dialog-header-close" href="">
    <ik-icon name="close"></ik-icon>
  </a>
</header>
<section class="dialog-content">
  <p *ngIf="!(updateService.downloading$ | async)">{{ 'common.form.updatePrompt' | translate }}</p>
  <div *ngIf="(updateService.downloading$ | async) && !(updateService.downloaded$ | async)">
    <div class="progress">
      <div [style.width.%]="(updateService.progress$ | async)?.percent || 0" class="progress-line">
        <div *ngIf="(updateService.progress$ | async)?.percent > 10" class="progress-text">
          {{ (updateService.progress$ | async)?.percent | number: '1.0-0' }}
          %
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="updateService.downloaded$ | async">
    {{ 'common.form.downloading' | translate }}
  </div>
</section>

<footer *ngIf="!(updateService.downloading$ | async)" class="dialog-footer end">
  <button (click)="cancel($event)" ik-button="secondary">{{ 'common.form.later' | translate }}</button>
  <button (click)="download($event)" ik-button>{{ 'common.form.yes' | translate }}</button>
</footer>
