import instructions from '../../../../electron-app/src/shared-data/equipments.json';
import { EquipmentInstruction } from '@ik/shared-data/schema';

export class InstructionNotFoundError extends Error {
  constructor(model: string) {
    super(`Instructions not found for ${model} model name`);
  }
}

export class ConnectionTypeNotSupportedError extends Error {
  constructor(type: string, model: string) {
    super(`Connection type "${type}" not supported for ${model}`);
  }
}

export class StepTypeNotSupportedError extends Error {
  constructor(type: string, model: string) {
    super(`Step type "${type}" not supported for ${model}`);
  }
}

export class EquipmentInstructions {
  model: string;
  number: string;
  private readonly instruction: EquipmentInstruction;

  constructor(model: string) {
    const formattedModel = model.split(' ');
    this.instruction = (instructions as any[]).find((val: EquipmentInstruction) => {
      const modelName = formattedModel.slice(0, -1).join(' ');
      return val.model.toLowerCase() === model.toLowerCase() || val.model === modelName;
    }) as EquipmentInstruction;
    if (!this.instruction) {
      throw new InstructionNotFoundError(model);
    }
    this.model = this.instruction.model;
    this.number = formattedModel.reverse()[0];
  }

  static models() {
    return instructions.map((instruction) => instruction.model);
  }

  static modelWithDisplayName() {
    return instructions.map((instruction) => {
      var data = { model: '', displayName: '' };
      data.model = instruction.model;
      data.displayName = instruction.displayName ? instruction.displayName : instruction.model;
      return data;
    });
  }

  static modelsWithNumbers() {
    return instructions.map((instruction) => ({
      model: instruction.model,
      numbers: instruction.modelNumbers,
      displayName: instruction.displayName,
    }));
  }

  static modelsWithConnections(): any[] {
    return instructions
      .map((instruction: any) => {
        return instruction.connectionTypes.map((connection) => {
          return { model: instruction.model, type: connection.type };
        });
      })
      .reduce((a, b) => a.concat(b), []);
  }

  connectionParams(type: string) {
    const connectionParams = this.instruction.connectionTypes.find(
      (connectionType) => connectionType.type.toLowerCase() === type.toLowerCase()
    );

    if (!connectionParams) {
      throw new ConnectionTypeNotSupportedError(type, this.model);
    }

    return connectionParams;
  }

  availableConnectionTypes() {
    return this.instruction.connectionTypes.map((v) => v.type);
  }

  stepTypeConfig(type: string) {
    const stepParams = this.getStepParams().find((params) => params.stepType.toLowerCase() === type.toLowerCase());

    if (!stepParams) {
      throw new StepTypeNotSupportedError(type, this.model);
    }

    return stepParams;
  }

  stepProperties(type: string) {
    return disableIfValidator(this.stepTypeConfig(type).properties, this.number);
  }

  stepDependence(type: string) {
    return this.stepTypeConfig(type).dependence;
  }

  scannerProperties(type) {
    return this.stepProperties(type).filter((prop) => ['single_channel_scanner', 'scanner'].includes(prop.type));
  }

  availableStepTypes() {
    return this.getStepParams().map((v) => v.stepType);
  }

  availableStepDescription() {
    return this.getStepParams().map((v) => v.description);
  }

  availableStepDependance() {
    return this.getStepParams().map((v) => v.dependence);
  }

  getStepParams() {
    return disableIfValidator(this.instruction.stepParams, this.number);
  }

  getModel() {
    return this.instruction.model;
  }

  getCommands() {
    return this.instruction.commands;
  }

  getAddStepCommand() {
    return this.instruction.addStepCommand;
  }

  getListStepParamsCommand() {
    return this.instruction.listStepParamsCommand;
  }

  getModelNumbers(): string[] {
    return this.instruction.modelNumbers;
  }

  getMemorySize() {
    return this.instruction.memorySize;
  }

  getMemoryLimit() {
    return this.instruction.memoryLimit;
  }

  hasMemoryLimit(): boolean {
    if (
      this.instruction.memoryLimit &&
      this.instruction.memoryLimit?.fileLimit &&
      this.instruction.memoryLimit?.stepLimit
    ) {
      return true;
    }
    return false;
  }

  getInstruction() {
    return this.instruction;
  }
}

function disableIfValidator(params: any[], number: string) {
  return params.filter((param) => {
    if (param?.validators?.hasOwnProperty('disable_if')) {
      if (!param?.validators?.disable_if?.model_number?.includes(number)) {
        return param;
      }
    } else {
      return param;
    }
  });
}
