import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AdminCompaniesTableEffects } from './admin-companies.effect';
import { adminCompaniesTableReducer } from './admin-companies.ngrx';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('admin-companies', {
      AdminCompaniesTable: adminCompaniesTableReducer
    }),
    EffectsModule.forFeature([AdminCompaniesTableEffects])
  ],
  providers: [AdminCompaniesTableEffects]
})
export class AdminCompaniesModule {}
