<div class="ik-alert-overlay">
  <div class="spinner-wrapper" *ngIf="data.loading">
    <div class="sk-circle">
      <div class="sk-circle1 sk-child"></div>
      <div class="sk-circle2 sk-child"></div>
      <div class="sk-circle3 sk-child"></div>
      <div class="sk-circle4 sk-child"></div>
      <div class="sk-circle5 sk-child"></div>
      <div class="sk-circle6 sk-child"></div>
      <div class="sk-circle7 sk-child"></div>
      <div class="sk-circle8 sk-child"></div>
      <div class="sk-circle9 sk-child"></div>
      <div class="sk-circle10 sk-child"></div>
      <div class="sk-circle11 sk-child"></div>
      <div class="sk-circle12 sk-child"></div>
    </div>
  </div>

  <div class="content-container" [class.loading]="data.loading">
    <div *ngIf="data.content" class="content">
      {{ data.content }}
    </div>

    <div *ngIf="data.filePath && data.fileName" class="open-file">
      <a (click)="onClickOpenFile()">{{ data.fileName }}</a>
    </div>

    <div *ngIf="data.folderPath" class="open-folder">
      <a (click)="onClickOpenFolder()">Open containing folder</a>
    </div>
  </div>

  <div *ngIf="data.showCloseBtn" class="close">
    <a title="Close" (click)="onClickClose()">
      <ik-icon name="close"></ik-icon>
    </a>
  </div>
</div>