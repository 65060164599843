import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { BaseReportsService } from '@ik/shared-data/base-services/base-reports.service';
import { reportsTable } from './reports-table.ngrx';

@Injectable()
export class ReportsTableEffects {

  constructor(
    private actions$: Actions,
    private reportsService: BaseReportsService
  ) { }

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.reportsService.getReports()),
      map(res => {
        return new reportsTable.actions.LoadDataSuccess(res);
      })
    )
  );

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsTable.actionTypes.changeSort),
      map(() => {
        return new reportsTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsTable.actionTypes.changePage),
      map(() => {
        return new reportsTable.actions.LoadData();
      })
    )
  );

  filtersChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(reportsTable.actionTypes.changeFilters),
      map(() => {
        return new reportsTable.actions.LoadData();
      })
    )
  );
}
