import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface Info {
  firmware: string;
  manufacturer: string;
  model_number: string;
  serial_number: string;
  model: string;
  connectionType: string;
  address: string;
  calibrationDate: string;
  scannerStatus?: number;
  main: boolean;
}

export interface Step {
  result: string;
  step: number;
  stepType: string;
  [key: string]: any;
}

export interface EquipmentTest {
  steps: Step[];
  stepsCount: number;
  id: number;
  name: string;
  dbInfo?: any;
}

export interface Result {
  step: number;
  testType: string;
  expt_result?: string;
  status: string;
  meter1: string;
  meter2: string;
  meter3: string;
  meter4?: string;
  meter5?: string;
  meter6?: string;
  passed?: boolean;
  failed?: boolean;
  stepStartedAt?: string;
}

export interface TestProgress {
  selectedFile: number;
  selectedStep: number;
  failStop: boolean;
  singleStep: boolean;
  interlock: boolean;
  testInProgress: boolean;
  testFinished: boolean;
  results: Result[];
  promptMessage: string;
  overallStatus: string;
  eventStatuses: any[];
  currentStep: number;
  reportId: number;
  resetStep: any;
  testStartedAt?: string;
  testedStep: number;
}

export interface EquipmentState {
  connected: boolean;
  connectionPort: string;
  info: Info;
  tests: EquipmentTest[];
  report_id: number;
  testProgress: TestProgress;
}

export function createInitialState(): EquipmentState {
  return {
    connected: false,
    connectionPort: null,
    info: null,
    report_id: null,
    testProgress: null,
    tests: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'main-instrument' })
export class MainEquipmentStore extends Store<EquipmentState> {
  constructor() {
    super(createInitialState());
  }
}
