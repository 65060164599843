import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TestsTableEffects } from './tests-table.effects';
import { testsTableReducer } from './tests-table.ngrx';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('tests', {
      TestsTable: testsTableReducer
    }),
    EffectsModule.forFeature([TestsTableEffects])
  ],
  providers: [TestsTableEffects]
})
export class TestsStoreModule {}
