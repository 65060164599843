import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IconComponent } from './icon/icon.component';
import { SvgDefinitionsComponent } from './svg-definations/svg-definitions.component';

@NgModule({
  imports: [CommonModule],
  declarations: [IconComponent, SvgDefinitionsComponent],
  exports: [IconComponent, SvgDefinitionsComponent]
})
export class IconModule {}
