import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { UsersTableEffects } from './users-table-effects.service';
import { usersTableReducer } from './users-table.ngrx';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('users', {
      UsersTable: usersTableReducer
    }),
    EffectsModule.forFeature([UsersTableEffects])
  ],
  providers: [UsersTableEffects]
})
export class UsersStoreModule {}
