import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SnackbarEffects } from './effects';
import { snackbarReducer } from './reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('snackbar', snackbarReducer),
    EffectsModule.forFeature([SnackbarEffects])
  ],
  declarations: []
})
export class SnackbarStoreModule {}
