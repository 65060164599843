<div class="ik-form-field-wrapper">
  <div (click)="_control.onContainerClick && _control.onContainerClick($event)" class="ik-form-field-flex">
    <div #inputContainer class="ik-form-field-infix">
      <label
        #label
        *ngIf="_hasLabel()"
        [attr.aria-owns]="_control.id"
        [attr.for]="_control.id"
        [id]="_labelId"
        class="ik-form-field-label"
      >
        <ng-content select="ik-label"></ng-content>
        <span
          *ngIf="!hideRequiredMarker && _control.required && !_control.disabled"
          aria-hidden="true"
          class="ik-form-field-required-marker"
          >&nbsp;*</span
        >
      </label>
      <ng-content></ng-content>
    </div>
  </div>

  <!-- Underline used for legacy, standard, and box appearances. -->
  <div [ngSwitch]="_getDisplayedMessages()" class="ik-form-field-subscript-wrapper">
    <div *ngSwitchCase="'error'" [@transitionMessages]="_subscriptAnimationState">
      <ng-content select="ik-error"></ng-content>
    </div>

    <div *ngSwitchCase="'hint'" [@transitionMessages]="_subscriptAnimationState" class="ik-form-field-hint-wrapper">
      <div *ngIf="hintLabel" [id]="_hintLabelId" class="ik-hint">{{ hintLabel }}</div>
      <ng-content select="ik-hint:not([align='end'])"></ng-content>
      <div class="ik-form-field-hint-spacer"></div>
      <ng-content select="ik-hint[align='end']"></ng-content>
    </div>
  </div>
</div>
