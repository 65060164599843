import { Component, ElementRef, HostBinding, Input, OnInit } from '@angular/core';
import { ObservableInput } from 'observable-input';
import { fadeIn } from '../../../helpers/animations';

@Component({
  selector: 'button[ik-button],a[ik-button],button[ik-button-icon]', // eslint-disable-line
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  animations: [fadeIn]
})
export class ButtonComponent implements OnInit {
  @HostBinding('class.small')
  @Input()
  small = false;
  @HostBinding('class.loading')
  @Input()
  @ObservableInput()
  loading: boolean;
  private _prevTheme = null;

  constructor(private elem: ElementRef) {}

  @Input('ik-button')
  set theme(val: string) {
    const classList = this.elem.nativeElement.classList;
    if (this._prevTheme) {
      classList.remove(this._prevTheme);
    }
    if (val) {
      const className = `theme-${val}`;
      classList.add(className);
      this._prevTheme = className;
    }
  }

  ngOnInit() {}
}
