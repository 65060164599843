import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { InstrumentsState, InstrumentsStore } from './instruments.store';
import { DesktopQuery } from '../../state/desktop.query';

@Injectable({ providedIn: 'root' })
export class InstrumentsQuery extends QueryEntity<InstrumentsState> {
  constructor(protected store: InstrumentsStore, private desktopQuery: DesktopQuery) {
    super(store);
  }
}
