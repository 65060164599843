import { Component, OnDestroy, OnInit } from '@angular/core';
import { CompanyQuery } from '../state/company.query';
import { CompanyState } from '../state/company.store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'ik-company-inactive',
  templateUrl: './company-inactive.component.html',
  styleUrls: ['./company-inactive.component.css']
})
export class CompanyInactiveComponent implements OnInit, OnDestroy {
  company: CompanyState;
  constructor(private companyQuery: CompanyQuery) {}

  ngOnInit(): void {
    this.companyQuery
      .select()
      .pipe(untilDestroyed(this))
      .subscribe(company => {
        this.company = company;
      });
  }

  ngOnDestroy(): void {}
}
