import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const adminCompaniesTableName = 'AdminCompaniesTable';
export const adminCompaniesTable = TableReducer.reducerFactory(adminCompaniesTableName);

export function adminCompaniesTableReducer(state, action) {
  return adminCompaniesTable.reducer(state, action);
}

export const adminCompaniesTableSelectors = TableSelectors.selectorsFactory('admin-companies', adminCompaniesTableName);
