import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { EquipmentState } from './main-equipment.store';

export interface ActiveEquipmentState {
  data: EquipmentState[];
}

export function createInitialState(): ActiveEquipmentState {
  return {
    data: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'active-equipment' })
export class ActiveEquipmentStore extends Store<ActiveEquipmentState> {
  constructor() {
    super(createInitialState());
  }
}
