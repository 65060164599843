import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { AdminUsersService } from '../../services/admin-users.service';
import { adminUsersTable } from './admin-users-table.ngrx';

@Injectable()
export class AdminUsersTableEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUsersTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.adminUsersService.getUsers()),
      map(res => {
        return new adminUsersTable.actions.LoadDataSuccess(res);
      })
    )
  );

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUsersTable.actionTypes.changeSort),
      map(() => {
        return new adminUsersTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUsersTable.actionTypes.changePage),
      map(() => {
        return new adminUsersTable.actions.LoadData();
      })
    )
  );

  filtersChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(adminUsersTable.actionTypes.changeFilters),
      map(() => {
        return new adminUsersTable.actions.LoadData();
      })
    )
  );

  constructor(private actions$: Actions, private adminUsersService: AdminUsersService) {}
}
