import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map, mapTo } from 'rxjs/operators';
import { CompanyState, CompanyStore } from './company.store';

@Injectable({ providedIn: 'root' })
export class CompanyQuery extends Query<CompanyState> {
  maxInstruments$ = this.select('max_instruments');
  customReport$ = this.select('custom_reports');
  subscriptionStatus$ = this.select('subscription_status');
  hasPendingPayment$ = this.subscriptionStatus$.pipe(map(status => ['past_due', 'unpaid'].includes(status)));

  constructor(protected store: CompanyStore) {
    super(store);
  }
}
