import { DatePipe } from '@angular/common';
import { HttpClient, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import download from 'downloadjs';
import moment from 'moment';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { BaseReportsService } from '@ik/shared-data/base-services/base-reports.service';
import { CloudApi } from '@app/helpers/cloud.api';
import { DesktopApi } from '../helpers/desktop.api';
import { EquipmentInstructions } from '../helpers/equipment-instructions';
import { reportsTableSelectors } from '../root-store/reports-store/reports-table.ngrx';
import { TestResultsAttribute, ReportCreateRequest, ReportResponse, CSVData } from '../../typings/reports';
import { PaginatedData } from '../../typings/common';
import { InstrumentsQuery } from '../equipment/state/instruments.query';
import { MainEquipmentQuery } from '../state/main-equipment.query';
import { DesktopQuery } from '../state/desktop.query';
import { Report } from '../reports/state/reports.store';
import { LocalTestStore } from '../root-store/local-tests-store/local-tests.store';
import { TranslateService } from '@ngx-translate/core';
import { OverlayService } from './overlay.service';
import { AlertOverlayComponent } from '@app/ui/alert-overlay/alert-overlay.component';
import { isEmpty, getFormattedDate, extractFilename, isoDateFormat } from '../helpers/utils';

@Injectable({
  providedIn: 'root',
})
export class ReportsService extends BaseReportsService {
  loading: boolean = false;
  public exporting: boolean = false;

  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private store: Store<any>,
    private instrumentsQuery: InstrumentsQuery,
    private mainEquipmentQuery: MainEquipmentQuery,
    private desktopQuery: DesktopQuery,
    private localTestStore: LocalTestStore,
    private translate: TranslateService,
    private overlayService: OverlayService
  ) {
    super();
  }

  getById(id: any) {
    return this.http.get<ReportResponse>(`${this.cloudApi.reports}/${id}`);
  }

  getLocalReportById(id: any): Observable<ReportResponse> {
    return this.localTestStore.getReportById(id);
  }

  getLocalReportForPDF(ids: number[] | string[]) {
    if (ids && ids.length > 0) {
      return this.localTestStore.getLocalReportForPDF(ids).pipe(
        map((reports) => {
          return {
            data: reports.data.map((report) => ({
              ...report,
              equipment_attributes: {
                ...report.equipment_attributes,
                model: `${report.equipment_attributes.equipment_type} ${report.equipment_attributes.model_number}`,
              },
            })),
          };
        })
      );
    }
  }

  getByReportNumber(ids: number[] | string[]) {
    return this.http
      .get<{ data: ReportResponse[] }>(`${this.cloudApi.reports}`, { params: { report_numbers: ids.join(',') } })
      .pipe(
        map((reports) => {
          return {
            data: reports.data.map((report) => ({
              ...report,
              equipment_attributes: {
                ...report.equipment_attributes,
                model: `${report.equipment_attributes.equipment_type} ${report.equipment_attributes.model_number}`,
              },
            })),
          };
        })
      );
  }

  // eslint-disable-next-line
  getReports() {
    return combineLatest([
      this.store.pipe(select(reportsTableSelectors.selectPage)),
      this.store.pipe(select(reportsTableSelectors.selectFilters)),
      this.store.pipe(select(reportsTableSelectors.selectSort)),
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters).reduce(function (result: any, key) {
          if (key === 'created') {
            if (filters[key].from) {
              result.created_from = moment(filters[key].from).format('YYYY-MM-DD');
            }
            if (filters[key].to) {
              result.created_to = moment(filters[key].to).format('YYYY-MM-DD');
            }
            return result;
          }
          const value = filters[key] && filters[key].toString().trim();
          if (value) {
            result[key] = value;
          }
          return result;
        }, {});
        const params = new HttpParams({
          fromObject: {
            ...preparedFilters,
            page: page.toString(),
            order: order || '-report_number',
          },
        });
        return this.getPaginatedReports(params);
      })
    );
  }

  getPaginatedReports(params: HttpParams): Observable<PaginatedData<Report>> {
    return this.http.get<PaginatedData<Report>>(this.cloudApi.reports, { params });
  }

  storeReport(dut?: any) {
    return combineLatest([
      this.mainEquipmentQuery.info$,
      this.mainEquipmentQuery.testProgress$,
      this.mainEquipmentQuery.activeTest$,
      this.instrumentsQuery.selectAll(),
      this.desktopQuery.hostname$,
    ]).pipe(
      take(1),
      switchMap(([info, testProgress, test, list, hostname]) => {
        const active = list.find((equipment) => equipment.serial_number === info.serial_number);
        const data: ReportCreateRequest = {
          equipment_id: active.id,
          test_name: test?.name,
          test_id: test?.dbInfo?.id,
          station_name: hostname,
          started_at: testProgress?.testStartedAt,
          overall_status: testProgress?.overallStatus,
          ...dut,
          test_results_attributes: testProgress.results.map((result) => {
            return {
              step: result.step,
              status: result.status,
              test_type: result.testType,
              expt_result: result.expt_result,
              meter1: result.meter1,
              meter2: result.meter2,
              meter3: result.meter3,
              meter4: result.meter4,
              meter5: result.meter5,
              meter6: result.meter6,
              started_at: result.stepStartedAt,
            };
          }),
        };
        return this.http.post(this.cloudApi.reports, data);
      })
    );
  }

  storeLocalReport(dut?: any) {
    return combineLatest([
      this.mainEquipmentQuery.info$,
      this.mainEquipmentQuery.testProgress$,
      this.mainEquipmentQuery.activeTest$,
      this.instrumentsQuery.selectAll(),
      this.desktopQuery.hostname$,
    ]).pipe(
      take(1),
      switchMap(([info, testProgress, test, list, hostname]) => {
        const id = this.localTestStore.getId();
        let data: ReportResponse = this.buildReportData(id, info, testProgress, test, list, hostname, dut);
        this.localTestStore.saveReport(data);
        return of(data);
      })
    );
  }

  private buildReportData(id, info, testProgress, test, list, hostname, dut): ReportResponse {
    const active = list.find((equipment) => equipment.serial_number === info.serial_number);
    const date = new Date();
    const data: ReportResponse = {
      id: id,
      report_number: id,
      test_name: test.name,
      test_id: test.dbInfo.id,
      equipment_id: active.id,
      station_name: hostname,
      overall_status: testProgress?.overallStatus,
      started_at: testProgress?.testStartedAt,
      created_at: date,
      updated_at: date,
      equipment_attributes: active,
      isLocalReport: true,
      test: test.dbInfo,
      ...dut,
      test_results_attributes: testProgress.results.map((result) => {
        return {
          step: result.step,
          status: result.status,
          test_type: result.testType,
          expt_result: result.expt_result,
          meter1: result.meter1,
          meter2: result.meter2,
          meter3: result.meter3,
          meter4: result.meter4,
          meter5: result.meter5,
          meter6: result.meter6,
          started_at: result.stepStartedAt,
        };
      }),
    };

    return data;
  }

  public download(reportIds: any[]) {
    const datePipe = new DatePipe('en');
    return this.store.pipe(
      select(reportsTableSelectors.selectData),
      take(1),
      map((data: any[]) => {
        const filtered = data.filter((row) => reportIds.includes(row.id));
        const csvData = filtered
          .reduce((accum, curr: any) => {
            [...curr.test_results_attributes]
              .sort((a, b) => (a.step > b.step ? 1 : -1))
              .forEach((res) => {
                accum.push(
                  [
                    curr.report_number,
                    curr.test_name,
                    `${curr.equipment_attributes.equipment_type} ${curr.equipment_attributes.model_number}`,
                    curr.equipment_attributes.serial_number,
                    curr.dut_model,
                    curr.dut_serial,
                    datePipe.transform(curr.created_at, 'medium'),
                    res.step,
                    res.test_type,
                    res.status,
                    this.prepareMeter(res, 'meter1', curr),
                    this.prepareMeter(res, 'meter2', curr),
                    this.prepareMeter(res, 'meter3', curr),
                    this.prepareMeter(res, 'meter4', curr),
                    this.prepareMeter(res, 'meter5', curr),
                    this.prepareMeter(res, 'meter6', curr),
                  ]
                    .map((item) => `"${item}"`)
                    .join(',')
                );
              });
            return accum;
          }, [])
          .join('\n');
        return {
          name: `Report_from_${moment().format('YYYYMMDDhhmmss')}`,
          // eslint-disable-next-line
          content: `Test ID,Test File Name,Instrument,Serial #,DUT Model,DUT Serial,Date & Time,Step,Type,Status,Meter 1,Meter 2,Meter 3,Meter 4,Meter 5,Meter 6\n${csvData}`,
        };
      }),
      tap((file) => {
        download(file.content, `${file.name}.csv`, 'text/csv');
      })
    );
  }

  public downloadCSV(reportIds: any[], data: any) {
    return data.pipe(
      take(1),
      map((data: any[]) => {
        const filtered = data.filter((row) => reportIds.includes(row.id));
        return this.generateCSVFile(filtered);
      }),
      tap((file: any) => {
        download(file.content, `${file.name}.csv`, 'text/csv');
      })
    );
  }

  public generateCSVFile(filtered) {
    const datePipe = new DatePipe('en');
    const csvData = filtered
      .reduce((accum, curr: any) => {
        [...curr.test_results_attributes]
          .sort((a, b) => (a.step > b.step ? 1 : -1))
          .forEach((res) => {
            accum.push(
              [
                curr.report_number,
                curr.test_name,
                `${curr.equipment_attributes.equipment_type} ${curr.equipment_attributes.model_number}`,
                curr.equipment_attributes.serial_number,
                curr.dut_model,
                curr.dut_serial,
                datePipe.transform(curr.created_at, 'medium'),
                res.step,
                res.test_type,
                res.status,
                this.prepareMeter(res, 'meter1', curr),
                this.prepareMeter(res, 'meter2', curr),
                this.prepareMeter(res, 'meter3', curr),
                this.prepareMeter(res, 'meter4', curr),
                this.prepareMeter(res, 'meter5', curr),
                this.prepareMeter(res, 'meter6', curr),
              ]
                .map((item) => `"${item}"`)
                .join(',')
            );
          });
        return accum;
      }, [])
      .join('\n');
    return {
      name: `Report_from_${moment().format('YYYYMMDDhhmmss')}`,
      // eslint-disable-next-line
      content: `Test ID,Test File Name,Instrument,Serial #,DUT Model,DUT Serial,Date & Time,Step,Type,Status,Meter 1,Meter 2,Meter 3,Meter 4,Meter 5,Meter 6\n${csvData}`,
      mimeType: 'text/csv',
    } as CSVData;
  }

  public uploadReport(report: FormData) {
    return this.http.post(`${DesktopApi.uploadReport}`, report).pipe(
      catchError((err) => {
        return throwError(err);
      })
    );
  }

  private prepareMeter(row: TestResultsAttribute, meterKey: string, report: ReportResponse) {
    if (!row[meterKey]) {
      return '';
    }
    const model = report?.equipment_attributes?.equipment_type;
    const number = report?.equipment_attributes?.model_number;
    const modelNumber = `${model} ${number}`;
    const instruction = new EquipmentInstructions(modelNumber);
    const meters = instruction.stepTypeConfig(row.test_type).meterUnits;
    const meterConf = meters.find((v) => v.key === meterKey);

    if (!meterConf) {
      return '';
    }

    // TODO: Check why the following `meterUnit` variable is not used
    // It looks like it was supposed to be used as parameter in `this.replaceOhmsSymbol()` for `newUnit`.
    let meterUnit = meterConf.unit;
    if (model == 'HypotMax' && meterKey == 'meter3') {
      const obj = report.test?.steps_attributes.find((x) => x.id == row.id);
      if (obj) {
        if (obj.parameters['dwell_unit'] == 'Second') {
          meterUnit = 's';
        } else if (obj.parameters['dwell_unit'] == 'Minute') {
          meterUnit = 'm';
        }
      }
    }

    const newUnit = this.replaceOhmsSymbol(meterConf.unit);
    return `${row[meterKey]}${newUnit}`;
  }

  private replaceOhmsSymbol(unit: any) {
    let ohms = unit.replace(/Ω/g, 'Ohms');
    return ohms.replace(/\u2126/g, 'Ohms');
  }

  print(config: any): Observable<Blob> {
    config['language'] = this.translate.currentLang;
    return this.http.post(`${this.cloudApi.reports}/print`, { report: config }, { responseType: 'blob' });
  }

  public exportFilteredCSV(filters: any) {
    this.exporting = true;

    if (this.overlayService.isOpen()) {
      this.overlayService.close();
    }
    this.overlayService.open(AlertOverlayComponent, { content: 'Exporting CSV file...', loading: true });

    this.downloadSteamCsv(filters) // Use the streaming method here
      .pipe(
        catchError((error) => {
          console.error('Error occurred:', error);

          this.overlayService.close();
          this.overlayService.open(AlertOverlayComponent, {
            content: 'Failed to export CSV file. Please try again later.',
            loading: false,
          });

          return throwError(error);
        }),
        finalize(() => {
          setTimeout(() => {
            this.exporting = false;
            this.overlayService.close();
          }, 6000);
        })
      )
      .subscribe((event) => {
        if (event.type === 'progress') {
          console.log(`Progress: ${event.progress}%`);
          // Optionally, update the overlay content or progress bar with the current progress
        } else if (event.type === 'complete') {
          const response = event.response;
          const contentDisposition = response.headers ? response.headers.get('Content-Disposition') : '';
          const filename = extractFilename(contentDisposition) || getFormattedDate();

          setTimeout(() => {
            this.overlayService.close();
            this.overlayService.open(AlertOverlayComponent, {
              content: 'Export completed. Your CSV file is already downloaded.',
              loading: false,
            });
            this.exporting = false;
          }, 500);

          const blob = new Blob([response], { type: 'text/csv' });
          download(blob, `${filename}`, 'text/csv');
        }
      });
  }

  downloadSteamCsv(filter: any): Observable<any> {
    // No need to set 'Content-Type' header for a GET request
    const headers = new HttpHeaders();
    let params = new HttpParams();

    for (let key in filter) {
      if (key !== 'created' && key !== 'export' && filter.hasOwnProperty(key) && !isEmpty(filter[key])) {
        params = params.set(key, filter[key]);
      }
    }

    if (filter && filter.created) {
      if (filter.created.from) {
        params = params.set('created_from', moment(filter.created.from).format(isoDateFormat));
      }
      if (filter.created.to) {
        params = params.set('created_to', moment(filter.created.to).format(isoDateFormat));
      }
    }

    return this.http
      .get(`${this.cloudApi.reports}/export`, {
        params,
        headers,
        responseType: 'blob',
        observe: 'events',
        reportProgress: true,
      })
      .pipe(
        map((event) => {
          switch (event.type) {
            case HttpEventType.Response:
              return { type: 'complete', response: event.body };
            default:
              return { type: 'unknown', event };
          }
        })
      );
  }
}
