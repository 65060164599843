<footer>
  <div class="logo">
    <img
      class="footer-logo"
      [src]="appConfig.isOfflineApp
        ? '/assets/images/WithStand-Desk-logo-REV.png'
        : '/assets/images/WithStand-Logo-rev.png'"
    />
  </div>
  <div class="copy">{{ 'footer.copyrightText' | translate: {currentYear, appVersion} }}</div>
  <ik-language-selector></ik-language-selector>
</footer>
