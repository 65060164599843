import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { AuthQuery } from 'src/app/auth/state/auth.query';
import { SocketService } from 'src/app/services/socket.service';
import { CommandLogStore } from './command-log.store';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommandLogService {
  public log: any[] = [];
  private isDisconnected = true;
  private commandLogSubscription: Subscription;
  test: number=0;
  showCommandLog: boolean = false;

  constructor(
    private commandLogStore: CommandLogStore,
    private socketService: SocketService,
    private authQuery: AuthQuery,
  ) {
    this.showCommandLog = environment.showCommandLog;
  }

  start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(loggedIn => {
        if (loggedIn) {
          this.socketService.connect();
        } else {
          this.socketService.disconnect();
        }
      })
    );
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe(connected => {
      if (!connected) {
        this.disconnected();
      } else {
        this.processSocketConnection();
      }
    });
  }

  public disconnected() {
    if (this.commandLogSubscription) {
      this.commandLogSubscription.unsubscribe();
    }

    this.isDisconnected = true;
  }

  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }
    this.isDisconnected = false;
    this.commandLogSubscription = this.socketService.on(['command-log:send']).subscribe(data => {
      if(this.showCommandLog){
        this.commandLogStore.addLog(data);
      }
    });
  }

  getLog() {
    if(this.showCommandLog){
      return this.commandLogStore.getValue().log;
    }
    return [];
  }

  clearLog(){
    this.commandLogStore.reset();
  }
}


