import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { catchError, delay } from 'rxjs/operators';

import { transformPanel } from '../../helpers/animations';
import { AuthQuery } from '../../auth/state/auth.query';
import { CompanyQuery } from '../../state/company.query';
import { BaseAuthService } from '@ik/shared-data/base-services/base-auth.service';

@UntilDestroy()
@Component({
  selector: 'ik-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [transformPanel]
})
export class HeaderComponent implements AfterViewInit, OnDestroy {
  @ViewChild('mobileNav', { static: true })
  mobileNav: TemplateRef<any>;
  loggedIn$ = this.authQuery.loggedIn$.pipe(untilDestroyed(this));
  hasPendingPayment$ = this.companyQuery.hasPendingPayment$;

  private overlayInstance: OverlayRef;

  constructor(
    private elem: ElementRef,
    private overlay: Overlay,
    private viewContainerRef: ViewContainerRef,
    private router: Router,
    private authService: BaseAuthService,
    private authQuery: AuthQuery,
    private companyQuery: CompanyQuery,
  ) {}

  ngOnDestroy(): void {}

  public toggleNav() {
    const navPortal = new TemplatePortal(this.mobileNav, this.viewContainerRef);
    this.overlayInstance.attach(navPortal);
  }

  public ngAfterViewInit() {
    this.overlayInstance = this.overlay.create({
      hasBackdrop: false,
      height: '100%',
      width: '100%',
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy: this.overlay
        .position()
        .flexibleConnectedTo(this.elem)
        .withPositions([
          {
            originX: 'start',
            originY: 'top',
            overlayX: 'start',
            overlayY: 'top'
          }
        ])
    });

    // this.overlayInstance.
  }

  public close() {
    this.overlayInstance.detach();
  }

  public logout($event: any) {
    $event.preventDefault();
    this.authService
      .logout()
      .pipe(
        catchError(() => of(null)),
        delay(200)
      )
      .subscribe(() => {
        this.router.navigate(['/auth/login']);
      });
  }
}
