<div class="page-content">
  <ik-header> </ik-header>
  <ik-change-log></ik-change-log>
  <div class="page-container">
    <router-outlet></router-outlet>
  </div>
  <ik-svg-definitions></ik-svg-definitions>

  <div class="page-content-spacer"></div>
  <ik-footer></ik-footer>
</div>
