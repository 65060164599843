import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EquipmentState, MainEquipmentStore, Info } from './main-equipment.store';
import { ActiveEquipmentState, ActiveEquipmentStore } from './active-equipment.store';
import {
  catchError,
  concatMap,
  debounceTime,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  switchMap,
  tap,
  throttleTime,
  toArray,
} from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';
import { SocketService } from '../services/socket.service';
import { asyncScheduler, BehaviorSubject, from, of, Subscription, throwError } from 'rxjs';
import {
  MatLegacySnackBar as MatSnackBar,
  MatLegacySnackBarRef as MatSnackBarRef,
} from '@angular/material/legacy-snack-bar';
import { CustomSnackbarComponent } from '../shared/custom-snackbar/custom-snackbar.component';
import { CloudApi } from '@app/helpers/cloud.api';
import { OfflineApi } from '@offline-app/helpers/offline.api';
import { DesktopApi } from '../helpers/desktop.api';
import { EquipmentService } from '../services/equipment.service';
import { Instrument } from '../equipment/state/instrument.model';
import { ActiveEquipmentQuery } from './active-equipment.query';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { ErrorDialogComponent, ErrorDialogData } from '../shared/error-dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from '../ui/confirm-click/confirm-dialog/confirm-dialog.component';
import { environment } from '../../environments/environment';
import { TestsService } from '../services/tests.service';
import { BaseApi } from '@ik/shared-data/base.api';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class ActiveEquipmentService {
  private isDisconnected = true;
  private disconnectionEvent: Subscription;
  private activeUpdateEvent: Subscription;
  private logConnection: Subscription;
  private disconnectTimeout: any;
  private socketHasConnected: boolean = false;
  private loggedIn: boolean = true;

  private testsSyncSubject = new BehaviorSubject<boolean>(false);
  public testsSync = this.testsSyncSubject.asObservable();

  constructor(
    private mainEquipmentStore: MainEquipmentStore,
    private activeEquipmentQuery: ActiveEquipmentQuery,
    private activeEquipmentStore: ActiveEquipmentStore,
    private authQuery: AuthQuery,
    private socketService: SocketService,
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private equipmentService: EquipmentService,
    private dialog: MatDialog,
    private testsService: TestsService,
    private baseApi: BaseApi,
    private translate: TranslateService
  ) {}

  public start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(async (loggedIn) => {
        if (!loggedIn) {
          this.loggedIn = false;
          this.socketService.disconnect();
          this.disconnect(null);
          this.activeEquipmentStore.reset();
        } else {
          this.loggedIn = true;
        }
      })
    );
  }

  public checkConnectionEnabled(data: Instrument[]) {
    const active = this.activeEquipmentStore.getValue().data;
    if (active.length > 0) {
      let found = null;
      active?.forEach(
        (v: EquipmentState) => (found = data.find((instrument) => instrument.serial_number === v.info.serial_number))
      );
      if (!found) {
        this.disconnect(null);
      }
    }
  }

  private stop() {
    this.activeEquipmentStore.reset();
    this.disconnectionEvent?.unsubscribe();
    this.activeUpdateEvent?.unsubscribe();
    this.logConnection?.unsubscribe();
    this.isDisconnected = true;
    this.socketHasConnected = false;
  }

  private confirmDialog(title, text) {
    const dialogRef = this.dialog.open<ConfirmDialogComponent>(ConfirmDialogComponent, {
      data: {
        title,
        text,
      },
      width: '500px',
    } as MatDialogConfig<ConfirmDialogData>);
    return dialogRef.afterClosed();
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe((connected) => {
      if (!connected) {
        if (!this.disconnectTimeout && this.socketHasConnected === true && this.loggedIn === true) {
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: this.translate.instant('activeEquipment.serviceConnectionLost'),
            duration: 30 * 1000,
          });

          this.disconnectTimeout = setTimeout(() => {
            this.stop();

            this.confirmDialog(
              this.translate.instant('activeEquipment.serviceConnectionLostTitle'),
              this.translate.instant('activeEquipment.serviceConnectionLostMessage')
            );
          }, 30 * 1000);
        } else {
          this.stop();
        }
      } else {
        if (this.disconnectTimeout) {
          clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = null;
        }

        this.socketHasConnected = true;
        this.processSocketConnection();
      }
    });
  }

  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }
    this.isDisconnected = false;
    this.connectionEvents();
    let snackbar: MatSnackBarRef<CustomSnackbarComponent>;
    this.activeEquipmentQuery.data$
      .pipe(
        map((data) =>
          data.filter((eq) => {
            if (!(eq && eq.info && eq.info.model && eq.info.model_number)) {
              return false;
            } else {
              return !eq.info.model.startsWith('Multiplexer') && !eq.info.model_number.startsWith('SC6540');
            }
          })
        ),
        map((data) => data.filter((eq) => eq.hasOwnProperty('tests') && eq.tests.length)),
        map((data) =>
          data.filter((eq) => eq.tests.find((test) => !test.hasOwnProperty('dbInfo') && test.dbInfo == undefined))
        ),
        filter((data) => data.length > 0),
        tap(() => {
          this.testsSyncSubject.next(false);
          snackbar = this.snackBar.openFromComponent(CustomSnackbarComponent, {
            data: this.translate.instant('activeEquipment.testFilesSyncInProgress'),
            duration: 0,
          });
        }),
        debounceTime(1000),
        switchMap(async (data) => {
          const equipment = [];
          const tests = [];
          const err = [];
          for await (let eq of data) {
            equipment.push(eq);
            tests.push(
              await from(
                eq.tests.map((test) => {
                  return this.testsService.storeTest(test, eq.info).pipe(
                    catchError(() => {
                      err.push({
                        id: test.id,
                        name: test.name,
                        model: eq.info.model,
                        model_number: eq.info.model_number,
                      });
                      return of({ id: test.id, test: null });
                    })
                  );
                })
              )
                .pipe(
                  concatMap((test) => test),
                  toArray()
                )
                .toPromise()
            );
          }
          return { equipment, tests, err };
        }),
        switchMap(async (data) => {
          for (let i = 0; i < data.tests.length; i++) {
            await this.storeBatch(data.tests[i], data.equipment[i]['info']).toPromise();
          }
          return { err: data.err };
        }),
        tap((data) => {
          if (snackbar) {
            snackbar.dismiss();
            snackbar = null;
            this.testsSyncSubject.next(true);
            this.snackBar.openFromComponent(CustomSnackbarComponent, {
              data: this.translate.instant('activeEquipment.testFilesSynced'),
              duration: 5000,
            });
          }
          if (data && data.err && data.err.length) {
            const str = {};
            let msg = '';
            data.err.forEach((x) => {
              if (!str[x.model + ' ' + x.model_number]) {
                str[x.model + ' ' + x.model_number] = [];
              }
              str[x.model + ' ' + x.model_number].push(` ${x.name}`);
            });
            Object.keys(str).forEach((x) => {
              msg =
                msg +
                `${
                  str[x].length > 1
                    ? this.translate.instant('activeEquipment.cannotSyncMultiple')
                    : this.translate.instant('activeEquipment.cannotSyncSingle')
                } ${str[x]}  ${this.translate.instant('activeEquipment.fromWithStand', { x: x })}<br/>`;
            });
            this.dialog.open(ErrorDialogComponent, {
              width: '500px',
              data: {
                title: this.translate.instant('activeEquipment.syncTestFileErrorTitle'),
                text: `${msg}${this.translate.instant('activeEquipment.syncTestFileErrorMessage')}`,
              },
            } as MatDialogConfig<ErrorDialogData>);
          }
        })
      )
      .subscribe();
  }

  private connectionEvents() {
    this.disconnectionEvent = this.socketService
      .on(['active-equipment:disconnected'])
      .subscribe((data: EquipmentState) => {
        const index = this.activeEquipmentStore.getValue().data.findIndex((v: EquipmentState) => {
          return (
            v.info.model === data.info.model &&
            v.info.model_number === data.info.model_number &&
            v.info.serial_number === data.info.serial_number &&
            v.info.connectionType === data.info.connectionType &&
            v.info.address === data.info.address
          );
        });
        if (index > -1) {
          this.activeEquipmentStore.getValue().data.splice(index, 1);
          this.instrumentDisconnected(data);
        }
      });

    this.activeUpdateEvent = this.socketService
      .on(['active-equipment:connected', 'active-equipment:changes'])
      .pipe(throttleTime(environment.throttleTimeDelay, asyncScheduler, { leading: true, trailing: true }))
      .subscribe((data: any) => {
        if (data.hasOwnProperty('data')) {
          this.activeEquipmentStore.update(data);
        } else {
          const activeEquipment: EquipmentState = data;
          let store: { data: any[] } = { data: [] };
          this.activeEquipmentStore.getValue().data.forEach((v) => store.data.push(v));
          let index = store.data.findIndex((v: EquipmentState) => {
            return (
              v.info.model === activeEquipment.info.model &&
              v.info.model_number === activeEquipment.info.model_number &&
              v.info.serial_number === activeEquipment.info.serial_number &&
              v.info.connectionType === activeEquipment.info.connectionType &&
              v.info.address === activeEquipment.info.address
            );
          });

          if (index < 0) {
            index = store.data.length;
            store.data[index] = {};
          }

          if (data.hasOwnProperty('info')) {
            store.data[index]['info'] = activeEquipment.info;
          }
          if (data.hasOwnProperty('tests')) {
            store.data[index]['tests'] = activeEquipment.tests;
          }
          if (data.hasOwnProperty('testProgress')) {
            store.data[index]['testProgress'] = activeEquipment.testProgress;
          }

          if (!this.mainEquipmentStore.getValue().info && store.data[index]['info']['model'] !== 'Multiplexer') {
            this.mainEquipmentStore.update(store.data[index]);
            const equipmentInfo = {
              equipmentModel: store.data[index]['info']['model'],
              connectionType: store.data[index]['info']['connectionType'],
              address: store.data[index]['info']['address'],
            };
            this.equipmentService
              .setMainEquipment(equipmentInfo)
              .toPromise()
              .then((value) => console.log('Set Main Equipment: ', value));
          }
          this.activeEquipmentStore.update(store);
        }
        this.equipmentService.setConnection(false);
      });
    this.logConnection = this.socketService
      .on(['active-equipment:connected'])
      .pipe(filter((data) => !!data))
      .subscribe((data: any) => {
        if (data.hasOwnProperty('data')) {
          const activeEquipments: ActiveEquipmentState = data;
          activeEquipments.data.forEach((v: EquipmentState) => this.instrumentConnected(v));
        } else {
          const activeEquipment: EquipmentState = data;
          this.instrumentConnected(activeEquipment);
        }
      });
  }
  private instrumentDisconnected(equipment: EquipmentState) {
    const info = equipment.info;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('activeEquipment.instrumentDisconnected', {
        model: info.model,
        model_number: info.model_number,
        serial_number: info.serial_number,
      }),
      duration: 5000,
    });
  }

  private instrumentConnected(equipment: EquipmentState) {
    this.logConnected(equipment.info.serial_number, equipment.info.scannerStatus).subscribe();
    const info = equipment.info;
    this.snackBar.openFromComponent(CustomSnackbarComponent, {
      data: this.translate.instant('activeEquipment.instrumentConnected', {
        model: info.model,
        model_number: info.model_number,
        serial_number: info.serial_number,
      }),
      duration: 5000,
    });
  }

  private logConnected(serialNumber: string, scannerStatus: number) {
    return this.http.post(`${this.baseApi.equipments}/connect`, {
      serial_number: serialNumber,
      scanner_status: scannerStatus,
    });
  }

  private storeBatch(tests: any[], equipmentInfo: Info) {
    equipmentInfo['tests'] = tests;
    return this.http.post(`${DesktopApi.storeTest}/batch`, equipmentInfo).pipe(
      catchError((err) => this.storeConnectionCatchError(err)),
      finalize(() => {
        this.equipmentService.setConnection(false);
      })
    );
  }

  storeConnectionCatchError(err) {
    this.equipmentService.setConnection(false);
    return throwError(err);
  }

  private disconnect(data: { equipmentModel: string; connectionType: string; address: string }) {
    this.equipmentService.disconnect(data).subscribe();
  }

  public setTest(test: number) {
    return this.equipmentService.setTest(test);
  }

  public resetTest() {
    return this.equipmentService.reset();
  }
}
