import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import yaml from 'js-yaml';

@Component({
  selector: 'ik-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss'],
})
export class ChangeLogComponent implements OnInit {
  private bucketUrl = environment.bucketUrl;
  private changeLogStorage = 'changeLogStorage';

  public showChangeLog: boolean = false;
  public logFileVersion: string;
  public changeLog: string;

  constructor(private http: HttpClient, private translateService: TranslateService) {}

  ngOnInit(): void {
    this.logFileVersion = null;
    this.getLogVersions();
  }

  getLogVersions() {
    return this.http.get(`${this.bucketUrl}/LOG/change_logs_latest.yml`, { responseType: 'text' }).subscribe({
      next: (data) => {
        const logVersion = yaml.load(data);
        const fileNameByLanguage = {
          en: logVersion.en_file_name,
          es: logVersion.es_file_name,
        };
        const fileName = fileNameByLanguage[this.translateService.currentLang] || logVersion.en_file_name;

        if (logVersion && logVersion.version && fileName) {
          const filePath = `${this.bucketUrl}/LOG/${fileName}`;
          this.logFileVersion = logVersion.version;

          if (filePath && filePath.length > 0) {
            this.getChangeLog(filePath);
          }
        }
      },
      error: (err) => {
        this.showChangeLog = false;
      },
    });
  }

  getChangeLog(url: any) {
    this.showChangeLog = false;
    this.http.get(url, { responseType: 'text' }).subscribe({
      next: (data) => {
        this.changeLog = data;
        const logVersion = this.getPreviousLogVersion();

        if (logVersion) {
          if (logVersion != this.logFileVersion) {
            this.showChangeLog = true;
          }
        } else {
          this.showChangeLog = true;
        }
      },
      error: (err) => {
        this.showChangeLog = false;
      },
    });
  }

  getPreviousLogVersion(): any {
    return localStorage.getItem(this.changeLogStorage);
  }

  setLog(log): any {
    return localStorage.setItem(this.changeLogStorage, log);
  }

  onClickAcknowledged() {
    this.showChangeLog = false;

    if (this.logFileVersion) {
      this.setLog(this.logFileVersion);
    }
  }
}
