import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, delay, finalize, switchMap, take } from 'rxjs/operators';
import { OfflineApi } from '@offline-app/helpers/offline.api';
import { AuthQuery } from '../auth/state/auth.query';
import { AuthService } from '../auth/state/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private offlineApi: OfflineApi,
    private router: Router,
    private authQuery: AuthQuery,
    private authService: AuthService
  ) { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!req.url.startsWith('/api') && !req.url.startsWith(this.offlineApi.base)) {
      return next.handle(req);
    }
    let newReq = req;
    if (req.method === 'POST') {
      const params = req.params.set('ngsw-bypass', 'true');
      newReq = req.clone({
        params
      });
    }

    return this.authQuery.token$.pipe(
      take(1),
      switchMap(token => {
        let updatedRequest = newReq.clone();
        if (token) {
          updatedRequest = newReq.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
        }
        return next.handle(updatedRequest).pipe(
          catchError((err: HttpErrorResponse) => {
            if (err.status === 401 && token) {
              return this.authService.logout().pipe(
                switchMap(() => throwError(err)),
                delay(200),
                finalize(() => {
                  this.router.navigate(['/']);
                })
              );
            }
            return throwError(err);
          })
        );
      })
    );
  }
}
