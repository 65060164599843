import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const reportsTableName = 'ReportsTable';
export const reportsTable = TableReducer.reducerFactory(reportsTableName);

export function reportsTableReducer(state, action) {
  return reportsTable.reducer(state, action);
}

export const reportsTableSelectors = TableSelectors.selectorsFactory('reports', reportsTableName);
