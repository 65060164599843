import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocalTestsTableEffects } from './local-tests-table.effects';
import { localTestsTableReducer } from './local-tests-table.ngrx';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('localTests', {
      LocalTestsTable: localTestsTableReducer
    }),

    EffectsModule.forFeature([LocalTestsTableEffects])
  ],
  providers: [LocalTestsTableEffects]
})
export class LocalTestsStoreModule {}
