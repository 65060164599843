import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CompanyState {
  id: number;
  name: string;
  owner_name: string;
  owner_email: string;
  phone_number: string;
  card_type: string;
  card_last4: string;
  card_exp_month: string;
  card_exp_year: string;
  card_holder: string;
  status: string;
  subscription_status: string;
  billing_cycle_date?: string;
  trial_ends_at?: string;
  subscription_active: boolean;
  trial_used: boolean;
  users_count: number;
  max_instruments: number;
  instruments: number;
  next_payment: number;
  balance: number;
  on_trial: boolean;
  custom_reports: boolean;
  offline_sync: boolean;
  subscription_updating: boolean;
  intent_client_secret?: string;
}

export function createInitialState(): CompanyState {
  return {
    id: null,
    name: null,
    owner_name: null,
    owner_email: null,
    phone_number: null,
    card_type: null,
    card_last4: null,
    card_exp_month: null,
    card_exp_year: null,
    card_holder: null,
    status: null,
    subscription_status: null,
    billing_cycle_date: null,
    trial_ends_at: null,
    subscription_active: null,
    trial_used: null,
    users_count: null,
    max_instruments: 0,
    on_trial: false,
    custom_reports: false,
    instruments: 0,
    next_payment: 0,
    balance: 0,
    offline_sync: false,
    subscription_updating: false
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'company' })
export class CompanyStore extends Store<CompanyState> {
  constructor() {
    super(createInitialState());
  }
}
