import { Injectable } from '@angular/core';
import { DesktopStore } from './desktop.store';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SocketService } from '../services/socket.service';
import { AuthQuery } from '../auth/state/auth.query';
import { DesktopAvailableInstrument } from '../../typings/desktop';
import { Subscription } from 'rxjs';
import { InstrumentsQuery } from '../equipment/state/instruments.query';
import { DesktopApi } from '../helpers/desktop.api';
import { HttpClient } from '@angular/common/http';

@Injectable({ providedIn: 'root' })
export class DesktopService {
  private hostNameSubscription: Subscription;
  private deviceListSubscription: Subscription;
  private isDisconnected = true;
  private deviceListSync: Subscription;
  constructor(
    private desktopStore: DesktopStore,
    private authQuery: AuthQuery,
    private socketService: SocketService,
    private instrumentsQuery: InstrumentsQuery,
    private http: HttpClient
  ) {}

  start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(loggedIn => {
        if (loggedIn) {
          this.socketService.connect();
        } else {
          this.socketService.disconnect();
          this.desktopStore.reset();
        }
      })
    );
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe(connected => {
      this.desktopStore.update({ connected });
      if (!connected) {
        console.log('socket disconnected');
        this.disconnected();
      } else {
        console.log('socket connected');
        this.processSocketConnection();
        // TODO: Move logic to desktop
        this.processInstrumentList();
      }
    });
  }

  public disconnected() {
    this.desktopStore.reset();
    if (this.hostNameSubscription) {
      this.hostNameSubscription.unsubscribe();
    }
    if (this.deviceListSubscription) {
      this.deviceListSubscription.unsubscribe();
    }
    if (this.deviceListSync) {
      this.deviceListSync.unsubscribe();
    }
    this.isDisconnected = true;
  }
  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }
    this.isDisconnected = false;
    this.hostNameSubscription = this.socketService.on(['hostname']).subscribe(hostname => {
      this.desktopStore.update({ hostname: hostname.name });
    });
    this.deviceListSubscription = this.socketService
      .on<DesktopAvailableInstrument[]>(['device-list'])
      .subscribe(availableInstruments => {
        this.desktopStore.update({ availableInstruments });
      });
  }

  private processInstrumentList() {
    this.deviceListSync = this.instrumentsQuery
      .selectAll()
      .pipe(
        debounceTime(500),
        switchMap(list => {
          const addresses = Array.from(
            new Set(
              list
                .map(eq =>
                  eq.workstations_attributes.map(v => ({
                    type: v.connection_type,
                    address: v.address,
                    model: eq.equipment_type
                  }))
                )
                .reduce((a, b) => a.concat(b), [])
            )
          );
          return this.storeKnownAddresses(addresses);
        })
      )
      .subscribe();
  }

  private storeKnownAddresses(addresses: any[]) {
    return this.http.post(DesktopApi.scan, addresses);
  }
}
