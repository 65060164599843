import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { debounceTime, startWith } from 'rxjs/operators';
import { OnboardingDirective } from './onboarding.directive';

export enum OnboardingGroups {
  equipmentIndicator = 'header-equipment-indicator',
  equipmentList = 'equipmentList',
}

@Injectable({
  providedIn: 'root',
})
export class OnboardingService {
  public autoShow = new BehaviorSubject(false);
  public finished = new Set<string>();
  public activeChanges = new BehaviorSubject<OnboardingDirective>(null);
  public displayScope = new BehaviorSubject<'MODEL' | 'ALL'>('ALL');
  public finishedChanges = new Subject<void>();
  public forceToggleOnboarding = new Subject<void>();
  availableChanges = new Subject<void>();
  private directives: Set<OnboardingDirective> = new Set<OnboardingDirective>();
  private availableIds: Set<string> = new Set<string>();
  private directivesChanges = new Subject<void>();

  private _active: OnboardingDirective;

  public get active(): OnboardingDirective {
    return this._active;
  }

  public set active(value: OnboardingDirective) {
    this._active = value;
    this.activeChanges.next(value);
  }

  private get items() {
    return Array.from(this.directives).sort((a, b) => (a.weight > b.weight ? 1 : -1));
  }

  register(directive: OnboardingDirective) {
    if (this.availableIds.has(directive.id)) {
      return false;
    }
    this.directives.add(directive);
    this.availableIds.add(directive.id);
    this.availableChanges.next();
    this.directivesChanges.next();
    this.toggleDisplayScope();
    return true;
  }

  unregister(directive: OnboardingDirective) {
    this.directives.delete(directive);
    this.availableIds.delete(directive.id);
    this.availableChanges.next();
    this.directivesChanges.next();
    this.toggleDisplayScope();
  }

  toggleDisplayScope() {
    const inModal = this.items.some((a) => a.inModal);
    this.displayScope.next(inModal ? 'MODEL' : 'ALL');
  }

  start() {
    const autoShow = JSON.parse(localStorage.getItem('onboardingAutoShow') || 'true');
    this.autoShow.next(autoShow);
    const finished = JSON.parse(localStorage.getItem('finishedOnboarding') || '[]');
    this.finished = new Set<string>(finished);
    this.finishedChanges.next();
    const subscription = new Subscription();
    subscription.add(
      this.finishedChanges.subscribe(() => {
        localStorage.setItem('finishedOnboarding', JSON.stringify(Array.from(this.finished)));
      })
    );
    subscription.add(
      this.displayScope.pipe(debounceTime(200)).subscribe((mode) => {
        const inModal = mode === 'MODEL';
        if (!this.active || this.active.inModal !== inModal) {
          const items = this.getActiveItems();
          this.active = items.length ? items[0] : null;
        }
      })
    );
    subscription.add(
      this.autoShow.subscribe(() => {
        localStorage.setItem('onboardingAutoShow', JSON.stringify(this.autoShow.getValue()));
      })
    );
    subscription.add(
      this.directivesChanges.pipe(startWith<void, null>(null), debounceTime(200)).subscribe(() => {
        if (!this.active) {
          const nextActive = this.items.find((item) => !this.finished.has(item.id));
          this.active = nextActive;
        }
      })
    );
    return subscription;
  }

  next() {
    if (!this.hasNext()) {
      return;
    }
    const group = this.getActiveItems();
    const idx = this.getCurrentActiveIndex();
    this.active = group[idx + 1];
  }

  prev() {
    if (!this.hasPrev()) {
      return;
    }
    const group = this.getActiveItems();
    const idx = this.getCurrentActiveIndex();
    this.active = group[idx - 1];
  }

  hasNext() {
    if (!this.active) {
      return false;
    }

    const group = this.getActiveItems();
    const idx = this.getCurrentActiveIndex();
    return idx !== -1 && idx !== group.length - 1;
  }

  hasPrev() {
    if (!this.active) {
      return false;
    }

    const idx = this.getCurrentActiveIndex();
    return idx !== -1 && idx !== 0;
  }

  public selectActive(id: string) {
    this.active = this.items.find((v) => v.id === id);
  }

  finish() {
    if (!this.active || this.hasNext()) {
      return;
    }
    this.getActiveItems().forEach((item) => {
      this.finished.add(item.id);
    });
    this.active = null;
    this.finishedChanges.next();
    this.forceToggleOnboarding.next();
  }

  public skipActive() {
    this.directives.forEach((directive) => {
      if (directive.disabled) {
        return;
      }
      this.finished.add(directive.id);
      this.finishedChanges.next();
      this.forceToggleOnboarding.next();
    });
  }

  public displayActive() {
    this.directives.forEach((directive) => {
      if (directive.disabled) {
        return;
      }
      this.finished.delete(directive.id);
      this.finishedChanges.next();
      this.forceToggleOnboarding.next();
    });
  }

  public toggleActive() {
    if (this.directives.size === 0) {
      return;
    }
    const first = this.directives.values().next().value;
    if (!first.attached) {
      this.displayActive();
    } else {
      this.skipActive();
    }
  }

  public restore() {
    this.items
      .filter((item) => this.availableIds.has(item.id))
      .forEach((item) => {
        if (item.disabled) {
          return;
        }
        this.finished.delete(item.id);
      });
    this.finishedChanges.next();
  }

  public resetAll() {
    this.finished = new Set<string>();
    this.finishedChanges.next();
    this.forceToggleOnboarding.next();
  }

  private getCurrentActiveIndex() {
    const group = this.getActiveItems();
    return group.findIndex((item) => item.id === this.active.id);
  }

  private getActiveItems() {
    const scope = this.displayScope.getValue();
    return this.items
      .filter((item) => !this.finished.has(item.id) && this.availableIds.has(item.id) && !item.disabled)
      .filter((item) => {
        return (scope === 'MODEL' && item.inModal) || scope === 'ALL';
      });
  }
}
