import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ControlPanelStore } from '../control-panel/control-panel.store';
import { CloudApi } from '@app/helpers/cloud.api';
import { AppConfig } from '@ik/shared-data/app.config';
import { BaseApi } from '@ik/shared-data/base.api';

export interface ScannerSetup {
  scanner_name: string;
  scanner_type: string;
  scanner_model: string;
  scanner_number: string;
  scanner_address?: string;
  scanner_channel_ext?: string;
  scanner_channel_ext_1?: string;
  scanner_channel_ext_2?: string;
  scanner_channel_ext_3?: string;
  scanner_channel_ext_4?: string;
  scanner_options_ext?: string;
  scanner_options_ext_1?: string;
  scanner_options_ext_2?: string;
  scanner_options_ext_3?: string;
  scanner_options_ext_4?: string;
  params?: any;
}

export interface ScannerParameters {
  model: string;
  model_number: string;
  address: string;
  scanner_setup: ScannerSetup;
}

@Injectable({
  providedIn: 'root',
})
export class ScannerSetupService {
  public scannerSetup: ScannerSetup;
  private localScannerStorageName = 'localScannerStorageName';
  private userId: number = null;

  constructor(
    private http: HttpClient,
    private baseApi: BaseApi,
    private cloudApi: CloudApi,
    private controlPanelStore: ControlPanelStore,
    private appConfig: AppConfig
  ) {}

  initScannerSetup(userId: number) {
    this.setLocalScannerStorageName(userId);
    this.get(userId)
      .toPromise()
      .then((scannerParams) => {
        if (Object.keys(scannerParams).length) {
          const haveChannels = Object.keys(scannerParams.scanner_setup)
            .filter((prop) => prop.startsWith('scanner_options_ext') || prop.startsWith('scanner_channel_ext'))
            .some((prop) => scannerParams.scanner_setup[prop]);
          if (haveChannels) {
            this.scannerSetup = scannerParams.scanner_setup;
          } else {
            this.scannerSetup = undefined;
          }
        }
      });
  }

  get(userId: number): Observable<ScannerParameters> {
    const setting = this.controlPanelStore.getValue().setting;
    if (!setting?.isLocalModeOn) {
      return this.http.get<ScannerParameters>(this.getscannerParamsAPI(userId));
    } else {
      return of(this.getlocalScannerSetup());
    }
  }

  delete(userId: number) {
    const setting = this.controlPanelStore.getValue().setting;
    if (!setting?.isLocalModeOn) {
      return this.http.delete<ScannerParameters>(this.getscannerParamsAPI(userId));
    } else {
      localStorage.removeItem(this.localScannerStorageName);
      return of({});
    }
  }

  edit(userId: number, scannerParams: ScannerParameters) {
    const data = {
      scanner_parameter: scannerParams,
    };

    const setting = this.controlPanelStore.getValue().setting;
    if (!setting?.isLocalModeOn) {
      return this.http.patch<ScannerParameters>(this.getscannerParamsAPI(userId), data);
    } else {
      return of(this.setlocalScannerSetup(data));
    }
  }

  create(userId: number, scannerParams: ScannerParameters) {
    const data = {
      scanner_parameter: scannerParams,
    };

    const setting = this.controlPanelStore.getValue().setting;
    if (!setting?.isLocalModeOn) {
      return this.http.post<ScannerParameters>(this.getscannerParamsAPI(userId), data);
    } else {
      return of(this.setlocalScannerSetup(data));
    }
  }

  reset() {
    this.scannerSetup = null;
  }

  private setLocalScannerStorageName(userId: number) {
    if (this.userId) {
      this.userId = userId;
      this.localScannerStorageName = this.localScannerStorageName + '_' + this.userId;
    }
  }

  // Scanner
  private getlocalScannerSetup(): any {
    const scannerSetup = localStorage.getItem(this.localScannerStorageName);
    if (scannerSetup && scannerSetup !== 'undefined') {
      return this.bindScannerParams(JSON.parse(scannerSetup));
    }
    return {};
  }

  private setlocalScannerSetup(scannerParams: any): any {
    localStorage.setItem(this.localScannerStorageName, JSON.stringify(scannerParams));
    return this.bindScannerParams(scannerParams);
  }

  private bindScannerParams(scannerParams) {
    if (scannerParams) {
      return scannerParams.scanner_parameter ? scannerParams.scanner_parameter : scannerParams;
    } else {
      return {};
    }
  }

  private getscannerParamsAPI(userId: number) {
    if (this.appConfig.isOfflineApp) {
      return this.baseApi.scannerParameter;
    } else {
      return `${this.cloudApi.users}/${userId}${this.cloudApi.scannerParameter}`;
    }
  }
}
