import { SnackbarAction, SnackbarActions } from './actions';

export interface State {
  show: boolean;
}

const initialState: State = {
  show: false
};

export function snackbarReducer(state: State = initialState, action: SnackbarAction) {
  switch (action.type) {
    case SnackbarActions.Close:
      return { ...state, show: false };
    case SnackbarActions.Open:
      return { ...state, show: true };
    default:
      return state;
  }
}
