import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { debounceTime } from 'rxjs/operators';
import { IkCheckboxChange } from '../../forms/checkbox/checkbox.component';
import { OnboardingService } from '../onboarding.service';

@UntilDestroy()
@Component({
  selector: 'ik-onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class OnboardingComponent implements OnInit, OnDestroy {
  @Input()
  id = '';
  @HostBinding('class.active')
  active = false;
  hasNext = false;
  hasBack = false;
  displayType: string;
  autoShow$ = this.service.autoShow;

  constructor(
    private service: OnboardingService,
    private changeDetectionRef: ChangeDetectorRef,
    private element: ElementRef
  ) {}

  private _text: string | TemplateRef<any> = '';

  public get text(): string | TemplateRef<any> {
    return this._text;
  }

  @Input()
  public set text(value: string | TemplateRef<any>) {
    this._text = value;
    this.displayType = 'string';
    if (value instanceof TemplateRef) {
      this.displayType = 'template';
    }
    this.changeDetectionRef.detectChanges();
  }

  ngOnInit() {
    this.service.availableChanges.pipe(debounceTime(100), untilDestroyed(this)).subscribe(() => {
      this.updateActions();
      this.changeDetectionRef.detectChanges();
    });
    this.service.activeChanges.pipe(untilDestroyed(this)).subscribe(active => {
      this.active = active && active.id === this.id;
      this.updateActions();
      this.changeDetectionRef.detectChanges();
    });
  }

  public ngOnDestroy(): void {}

  public setActive($event: MouseEvent) {
    $event.preventDefault();
    $event.stopPropagation();
    this.service.selectActive(this.id);
  }

  back($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.service.prev();
  }

  next($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.service.next();
  }

  @HostListener('document:click', ['$event.target'])
  clickOutside(target) {
    if (!this.active) {
      return;
    }
    const current = this.element.nativeElement as Element;
    if (!current.contains(target)) {
      this.close();
    }
  }

  close() {
    this.service.selectActive(null);
  }

  skip($event) {
    $event.preventDefault();
    this.service.skipActive();
  }

  finish($event: MouseEvent) {
    $event.preventDefault();
    this.service.finish();
  }

  public hideNotifications($event: MouseEvent) {
    $event.preventDefault();
    this.service.autoShow.next(false);
    this.service.skipActive();
  }

  private updateActions() {
    this.hasNext = this.service.hasNext();
    this.hasBack = this.service.hasPrev();
  }
}
