<div #origin="cdkOverlayOrigin" (click)="open()" cdk-overlay-origin class="user-menu">
  {{ 'header.myAccountDropdown.text' | translate }}
  <ik-icon name="arrow-down"></ik-icon>
</div>
<div class="mobile-menu">
  <ng-container *ngTemplateOutlet="tmpl"></ng-container>
</div>

<ng-template
  (backdropClick)="close()"
  (detach)="close()"
  [cdkConnectedOverlayMinWidth]="160"
  [cdkConnectedOverlayOffsetX]="-50"
  [cdkConnectedOverlayOffsetY]="3"
  [cdkConnectedOverlayOpen]="panelOpen"
  [cdkConnectedOverlayOrigin]="origin"
  [cdkConnectedOverlayPositions]="_positions"
  [cdkConnectedOverlayScrollStrategy]="_scrollStrategy"
  cdk-connected-overlay
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop"
  cdkConnectedOverlayHasBackdrop
  cdkConnectedOverlayLockPosition
>
  <div #panel [@transformPanel]="'showing'" class="profile-dropdown">
    <ng-container *ngTemplateOutlet="tmpl"></ng-container>
  </div>
</ng-template>

<ng-template #tmpl>
  <ng-content></ng-content>
</ng-template>
