import { Component, Input } from '@angular/core';

@Component({
  selector: 'ik-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class IconComponent {
  @Input()
  name: string;

  get absUrl() {
    return window.location.href;
  }
}
