import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { map } from 'rxjs/operators';
import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  token$ = this.select('token');
  loggedIn$ = this.select('token').pipe(map(token => !!token));
  user$ = this.select('user');
  role$ = this.select('user').pipe(map(user => user?.role));

  constructor(protected store: AuthStore) {
    super(store);
  }

  isRole(role: 'Owner' | 'Admin' | 'Member') {
    return this.role$.pipe(map(userRole => userRole === role));
  }
}
