import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { CompanyService } from '../state/company.service';
import { map, withLatestFrom } from 'rxjs/operators';
import { AuthQuery } from '../auth/state/auth.query';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionActiveGuard implements CanActivate {
  constructor(private companyService: CompanyService, private authQuery: AuthQuery, private router: Router) {}
  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.companyService.getData().pipe(
      withLatestFrom(this.authQuery.user$),
      map(([company, user]) => {
        const allowedStatuses = ['active', 'trialing', 'past_due'];
        if (allowedStatuses.includes(company.subscription_status)) {
          return true;
        }
        if (user.role !== 'Owner') {
          return this.router.parseUrl('/company-inactive');
        }
        return this.router.parseUrl('/company-profile/billing');
      })
    );
  }
}
