import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { DesktopAvailableInstrument } from '../../typings/desktop';

export interface DesktopState {
  connected: boolean;
  hostname: string;
  availableInstruments: DesktopAvailableInstrument[];
}

export function createInitialState(): DesktopState {
  return {
    connected: null,
    hostname: null,
    availableInstruments: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'desktop' })
export class DesktopStore extends Store<DesktopState> {
  constructor() {
    super(createInitialState());
  }
}
