import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CompanyState, CompanyStore } from './company.store';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { CloudApi } from '@app/helpers/cloud.api';
import { SubscriptionOptions } from '../plans/subscription.service';
import { loadStripe, PaymentIntent, Stripe, StripeError } from '@stripe/stripe-js';
import { environment } from '../../environments/environment';
import { from, Observable, of, throwError } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CompanyService {
  private stripe: Stripe;

  constructor(
    private api: HttpClient,
    private cloudApi: CloudApi,
    private companyStore: CompanyStore,
  ) { }

  getData() {
    return this.api.get<CompanyState>(this.cloudApi.company).pipe(tap(company => this.companyStore.update(company)));
  }

  update(company: any) {
    return this.api.patch<CompanyState>(this.cloudApi.company, { company });
  }

  updatePaymentMethod(data: { payment_method: string; card_holder: string }) {
    return this.api.post(`${this.cloudApi.attachCard}`, {
      payment_method_id: data.payment_method,
      card_holder: data.card_holder
    });
  }

  changeSubscription(subscription: SubscriptionOptions) {
    return this.api.post(`${this.cloudApi.subscription}`, subscription).pipe(
      catchError(err => {
        if (err?.error?.billing?.status === 'requires_action') {
          return this.getStripe().pipe(switchMap(stripe => stripe.confirmCardPayment(err.error.billing.client_secret)));
        }
        return throwError(err);
      })
    );
  }

  paymentErrors() {
    return this.api.get(`${this.cloudApi.subscription}/payment-errors`);
  }

  reattempt() {
    return this.api.post(`${this.cloudApi.subscription}/reattempt`, {});
  }

  cancelSubscription() {
    return this.api.delete(`${this.cloudApi.subscription}`);
  }

  resumeSubscription() {
    return this.api.post(`${this.cloudApi.subscription}/resume`, {});
  }

  getPaymentIntent(clientSecret: string): Observable<{ paymentIntent?: PaymentIntent; error?: StripeError }> {
    return this.getStripe().pipe(switchMap(stripe => stripe.retrievePaymentIntent(clientSecret)));
    // return start.pipe(tap)
  }

  processPaymentIntent(clientSecret: string) {
    return this.getStripe().pipe(
      switchMap(stripe => {
        return stripe.confirmCardPayment(clientSecret);
      })
    );
    // return start.pipe(tap)
  }

  getStripe() {
    let start = of(this.stripe);
    if (!this.stripe) {
      start = from(loadStripe(environment.stripeKey)).pipe(tap(stripe => (this.stripe = stripe)));
    }
    return start;
  }
}
