import { Component, Inject } from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';

export interface ConfirmDialogData {
  title: string;
  text: string;
  note?: string;
}

@Component({
  selector: 'ik-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogData
  ) {}

  public close($event) {
    $event.preventDefault();
    this.dialogRef.close();
  }

  public confirm($event) {
    $event.preventDefault();
    this.dialogRef.close(true);
  }
}
