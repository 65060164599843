import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { map, pluck, switchMap, take } from 'rxjs/operators';
import { CloudApi } from '@app/helpers/cloud.api';
import { usersTableSelectors } from '../root-store/users-store/users-table.ngrx';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private store: Store<any>
  ) { }

  getForFilter() {
    return this.http.get(this.cloudApi.users, { params: { per_page: '150' } }).pipe(pluck('data'));
  }

  getUsers() {
    return combineLatest([
      this.store.pipe(select(usersTableSelectors.selectPage)),
      this.store.pipe(select(usersTableSelectors.selectFilters)),
      this.store.pipe(select(usersTableSelectors.selectSort))
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters).reduce(function(result, key) {
          const value = filters[key] && filters[key].toString().trim();
          if (value) {
            result[key] = value;
          }
          return result;
        }, {});
        const params = new HttpParams({
          fromObject: {
            ...preparedFilters,
            page: page.toString(),
            order: order || 'first_name'
          }
        });
        return this.http.get(this.cloudApi.users, { params }).pipe(
          map((data: any) => {
            if (!data.data) {
              return {
                data,
                meta: {
                  pagination: {
                    per_page: data.length,
                    page: 1,
                    total_count: data.length,
                    limit_value: data.length
                  }
                }
              };
            }
            return data;
          })
        );
      })
    );
  }

  remove(id) {
    return this.http.delete(`${this.cloudApi.users}/${id}`);
  }

  invite(user) {
    return this.http.post(this.cloudApi.invitation, { user });
  }
}
