export function dcopy(target) {
  return JSON.parse(JSON.stringify(target));
}

/**
 * Adds a value to the copy object based on its type
 *
 * @api private
 */

function add(copy, key, value) {
  if (copy instanceof Array) {
    copy.push(value);
    return copy[copy.length - 1];
  } else if (copy instanceof Object) {
    copy[key] = value;
    return copy[key];
  }
}
