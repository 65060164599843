import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { transformPanel } from '../../../helpers/animations';

@Component({
  selector: 'ik-user-panel',
  exportAs: 'userPanel',
  templateUrl: './user-panel.component.html',
  styleUrls: ['./user-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [transformPanel]
})
export class UserPanelComponent implements OnInit {
  _scrollStrategy: ScrollStrategy;
  origin: any;
  _positions = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top'
    }
  ];

  constructor(private overlay: Overlay, private _changeDetectorRef: ChangeDetectorRef) {}

  _panelOpen = false;

  get panelOpen(): boolean {
    return this._panelOpen;
  }

  ngOnInit() {
    this._scrollStrategy = this.overlay.scrollStrategies.reposition();
  }

  public close() {
    if (this._panelOpen) {
      this._panelOpen = false;
      this._changeDetectorRef.markForCheck();
    }
  }

  public open() {
    if (this._panelOpen) {
      return;
    }
    this._panelOpen = true;
    this._changeDetectorRef.markForCheck();
  }
}
