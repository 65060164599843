import { MatLegacySnackBarConfig as MatSnackBarConfig } from '@angular/material/legacy-snack-bar';
import { Action } from '@ngrx/store';

export enum SnackbarActions {
  Open = '[Snackbar] Open',
  Close = '[Snackbar] Close',
}

export class SnackbarOpen implements Action {
  readonly type = SnackbarActions.Open;

  constructor(
    public payload: {
      message: string;
      action?: string;
      config?: MatSnackBarConfig;
    }
  ) {}
}

export class SnackbarClose implements Action {
  readonly type = SnackbarActions.Close;
}

export type SnackbarAction = SnackbarOpen | SnackbarClose;
