import pack from '../../package.json';

export const environment = {
  appVersion: pack.version,
  production: true,
  bucketUrl: 'https://storage.googleapis.com/withstand-public-storage/master',
  bucketOfflineAppUrl: 'https://storage.googleapis.com/withstand-public-storage/offline-app',
  conversionUrl: 'https://withstand-autoware-file-conversion.azurewebsites.net',
  stripeKey: 'pk_test_EPmYzQWnOOoe3T5YAZeVOCKO00LUq6Z6ja',
  desktopApp: 'http://localhost:3888',
  showCommandLog: false,
  showDownloadOfflineDesktop: true,
  throttleTimeDelay: 200,
  usbDriverPath:
    'https://20996632.fs1.hubspotusercontent-na1.net/hubfs/20996632/Downloadable-Assets/APT/APT-Instrument-Drivers/AR-EEC-USB-Driver103.exe',
  envName: 'Develop',
};
