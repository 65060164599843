import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { DesktopState, DesktopStore } from './desktop.store';

@Injectable({ providedIn: 'root' })
export class DesktopQuery extends Query<DesktopState> {
  connected$ = this.select('connected');
  hostname$ = this.select('hostname');
  availableInstruments$ = this.select('availableInstruments');

  constructor(protected store: DesktopStore) {
    super(store);
  }
}
