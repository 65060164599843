import { Injectable } from '@angular/core';
import { arrayFind, Query } from '@datorama/akita';
import { EquipmentState, MainEquipmentStore, Result, Step, EquipmentTest } from './main-equipment.store';
import { map, withLatestFrom } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';

export interface TestWithResults extends EquipmentTest {
  steps: StepWithResults[];
}

export interface StepWithResults extends Step {
  testResult?: Result;
}

export interface EquipmentUiActions {
  canSetSingleStep: boolean;
  canSetFailStop: boolean;
  canSelectTest: boolean;
  canTest: boolean;
}

@Injectable({ providedIn: 'root' })
export class MainEquipmentQuery extends Query<EquipmentState> {
  private $testSuccess = new BehaviorSubject<boolean>(false);

  info$ = this.select('info');
  testProgress$ = this.select('testProgress');
  tests$ = this.select('tests');

  testsToSynchronize$ = this.tests$.pipe(arrayFind((test) => test.dbInfo == null));
  activeFile$ = this.select((state) => state?.testProgress?.selectedFile);
  activeStep$ = this.select((state) => state?.testProgress?.selectedStep);
  testResults$ = this.select((state) => state?.testProgress?.results);
  overallState$ = this.select((state) => state?.testProgress?.overallStatus);
  promptMessage$ = this.select((state) => state?.testProgress?.promptMessage);
  reportId$ = this.select((state) => state?.testProgress?.reportId);
  testInProgress$ = this.select((state) => state?.testProgress?.testInProgress);

  activeTest$ = this.activeFile$.pipe(
    withLatestFrom(this.select('tests')),
    map(([activeFile, tests]) => {
      return tests.find((test) => test.id === activeFile);
    })
  );
  activeTestWithResults$ = combineLatest([this.activeTest$, this.testResults$]).pipe(
    map(([test, results]) => {
      if (!test) {
        return null;
      }
      test.steps = test.steps.map((step) => {
        return {
          ...step,
          testResult: results.find((result) => +result.step === +step.step),
        };
      });
      return test;
    })
  );

  /* Utils */
  testFinished$ = this.select((state) => state.testProgress?.testFinished);
  stepsFinishedSuccessfully$ = this.select(
    (state) =>
      state.testProgress.results &&
      state.testProgress.results.length &&
      state.testProgress.results.every((res) => res.passed)
  );
  testFinishedSuccessfully$ = combineLatest([this.testFinished$, this.stepsFinishedSuccessfully$]).pipe(
    map(([testFinished, stepsFinishedSuccessfully]) => testFinished && stepsFinishedSuccessfully)
  );
  /* UI */
  canSetSingleStep$ = this.select((state) => state?.testProgress?.testInProgress);
  canSetFailStop$ = this.select((state) => state?.testProgress?.testInProgress);
  canSelectTest$ = combineLatest([this.testFinishedSuccessfully$, this.select()]).pipe(
    map(
      ([testFinishedSuccessfully, state]) =>
        !state.testProgress?.testInProgress &&
        (!state.testProgress?.results || state.testProgress?.results.length === 0 || testFinishedSuccessfully)
    )
  );
  canTest$ = combineLatest([this.select(), this.activeTest$, this.testFinishedSuccessfully$, this.testFinished$]).pipe(
    map(([state, activeTest, testFinishedSuccessfully, testFinished]) => {
      return (
        (!state.testProgress?.interlock &&
          !!activeTest &&
          !state.testProgress?.testInProgress &&
          (testFinishedSuccessfully ||
            !state.testProgress?.results ||
            state.testProgress?.results.length === 0 ||
            (state.testProgress?.singleStep && !testFinished) ||
            testFinishedSuccessfully ||
            (!testFinishedSuccessfully && state.testProgress?.failStop && !testFinished) ||
            state.testProgress?.testedStep !== activeTest.steps.length)) ||
        (!state.testProgress?.testInProgress &&
          state.testProgress?.eventStatuses &&
          state.testProgress?.eventStatuses.includes('PROMPT'))
      );
    })
  );

  uiActions$ = combineLatest([this.canSetSingleStep$, this.canSetFailStop$, this.canSelectTest$, this.canTest$]).pipe(
    map(([canSetSingleStep, canSetFailStop, canSelectTest, canTest]) => ({
      canSetSingleStep,
      canSetFailStop,
      canSelectTest,
      canTest,
    }))
  );

  constructor(protected store: MainEquipmentStore) {
    super(store);
  }

  public connectTestSuccessChange(): Observable<boolean> {
    return this.$testSuccess.asObservable();
  }

  public testSuccessChange(value: boolean) {
    this.$testSuccess.next(value);
  }
}
