import { Component, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { DesktopUpdateService } from '../../services/desktop-update.service';

@Component({
  selector: 'ik-update-modal',
  templateUrl: './update-modal.component.html',
  styleUrls: ['./update-modal.component.scss'],
})
export class UpdateModalComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<UpdateModalComponent>, public updateService: DesktopUpdateService) {}

  ngOnInit() {}

  cancel($event) {
    $event.preventDefault();
    this.updateService.cancel();
    this.dialogRef.close();
  }

  download($event) {
    $event.preventDefault();
    this.updateService.download();
  }
}
