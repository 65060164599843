import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { Profile } from '../../../typings/user';

export interface AuthState {
  token: any;
  user?: Profile;
}

export function createInitialState(): AuthState {
  return {
    token: null,
    user: null
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }
}
