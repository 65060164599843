import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IkFormsModule } from '@app/ui/forms/ik-forms.module';
import { LanguageSelectorComponent } from './language-selector.component';

@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [CommonModule, IkFormsModule],
  exports: [LanguageSelectorComponent],
})
export class LanguageSelectorModule {}
