import { ConnectionPositionPair, Overlay, OverlayConfig, OverlayRef, PositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  ComponentRef,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
} from '@angular/core';
import { MatLegacyDialogContainer as MatDialogContainer } from '@angular/material/legacy-dialog';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { merge } from 'rxjs/internal/observable/merge';
import { startWith } from 'rxjs/internal/operators/startWith';
import { concatMap, debounceTime, filter, mapTo, take, takeUntil, takeWhile } from 'rxjs/operators';
import { OnboardingService } from './onboarding.service';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { TranslateService } from '@ngx-translate/core';
// eslint-disable  @angular-eslint/no-output-rename, @angular-eslint/no-input-rename

let uid = 1;

@Directive({
  selector: '[ikOnboarding]',
})
export class OnboardingDirective implements OnInit, OnDestroy {
  @Input()
  weightMultiplier = 1;
  @Input('ikOnboarding')
  id;
  @Input()
  width = 200;
  @Input('onboardingText')
  text;
  attached: boolean;
  weight = uid++;
  inModal = false;
  private disabledChange = new Subject<void>();
  @HostBinding('attr.disabled')
  private _disabledAttr;
  private overlayRef: OverlayRef;
  private added: boolean;
  private component: ComponentRef<OnboardingComponent>;
  private destroyed: boolean;
  private destroy$ = new Subject<void>();

  constructor(
    private onboardingService: OnboardingService,
    private overlay: Overlay,
    private elementRef: ElementRef,
    private changeDetectionRef: ChangeDetectorRef,
    private router: Router,
    private translateService: TranslateService,
    @Optional() private matDialog: MatDialogContainer
  ) {}

  private _disabled = false;

  public get disabled() {
    return this._disabled;
  }

  @Input()
  public set disabled(value: boolean) {
    this._disabled = value;
    this._disabledAttr = value || null;
    this.disabledChange.next();
  }

  public ngOnDestroy(): void {
    this.destroyed = true;

    if (this.component) {
      this.component.destroy();
      this.component = null;
    }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef.detach();
    }
    this.destroy$.next();
    this.destroy$.complete();
    if (this.added) {
      this.onboardingService.unregister(this);
    }
  }

  findAncestor(el, sel) {
    do {
      el = el.parentElement;
    } while (el && !(el.matches || el.matchesSelector).call(el, sel));
    return el;
  }

  public ngOnInit(): void {
    this.inModal = !!this.findAncestor(this.elementRef.nativeElement, '.mat-dialog-container');
    const size = this.elementRef.nativeElement.getBoundingClientRect();
    const y = Math.ceil(size.y / 30) * 30;
    this.weight = (size.x + y * 10000) * this.weightMultiplier;

    this.added = this.onboardingService.register(this);
    if (!this.added) {
      return;
    }
    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create(this.getOverlayConfig(this.elementRef));
    }
    const navigationEnd = this.router.events.pipe(
      filter((ev) => ev instanceof NavigationEnd),
      mapTo({ hideTips: true })
    );

    /**
     * We use any translation key since we are not interested in the translation value but to the
     * moment when the translation service is available.
     */
    const translationObservable = this.translateService.get('login.title').pipe(take(1));

    const onboardingObservable = merge(
      this.onboardingService.finishedChanges,
      this.onboardingService.autoShow,
      this.onboardingService.displayScope,
      navigationEnd,
      this.disabledChange
    ).pipe(
      startWith<void, null>(null),
      debounceTime(100),
      takeWhile(() => !this.destroyed),
      takeUntil(this.destroy$)
    );

    const translationServiceReady = translationObservable.pipe(concatMap(() => onboardingObservable));

    translationServiceReady.subscribe((evt: any) => {
      if (
        (this.onboardingService.displayScope.getValue() === 'MODEL' && !this.inModal) ||
        window.location.href.includes('billing')
      ) {
        this.destroyTip();
        return;
      }
      if (this.onboardingService.autoShow.getValue() === false) {
        if (evt && evt.hideTips) {
          this.destroyTip();
        }
        return;
      }
      this.renderTip();
    });

    this.onboardingService.forceToggleOnboarding.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.renderTip();
    });

    this.overlayRef.hostElement.classList.add('ik-onboarding-item');
    this.onboardingService.activeChanges
      .pipe(startWith(this.onboardingService.active), takeUntil(this.destroy$))
      .subscribe((active) => {
        if (active && active.id === this.id) {
          this.overlayRef.hostElement.classList.add('ik-onboarding-active');
        } else {
          this.overlayRef.hostElement.classList.remove('ik-onboarding-active');
        }
      });
  }

  private destroyTip() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
    if (this.component) {
      this.component.destroy();
      this.component = null;
    }
    this.attached = false;
  }

  private renderTip() {
    if (!this.added) {
      return;
    }
    if (!this.onboardingService.finished.has(this.id) && !this._disabled) {
      if (!this.overlayRef.hasAttached() && !this.attached) {
        this.attached = true;
        this.attachComponent();
      }
    } else {
      this.destroyTip();
    }
  }

  private attachComponent() {
    this.overlayRef.detach();
    if (!this.component) {
      this.component = this.overlayRef.attach(new ComponentPortal(OnboardingComponent));
    }
    this.component.instance.id = this.id;
    this.component.instance.text = this.text;
    this.component.changeDetectorRef.detectChanges();
  }

  private getOverlayConfig(origin): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: false,
      width: this.width,
      panelClass: 'onboarding-panel',
      positionStrategy: this.getOverlayPosition(origin),
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
    });
  }

  private getOverlayPosition(origin: HTMLElement): PositionStrategy {
    return this.overlay.position().flexibleConnectedTo(origin).withPositions(this.getPositions()).withPush(false);
  }

  private getPositions(): ConnectionPositionPair[] {
    return [
      {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        panelClass: 'pos-bottom-center',
        offsetY: 12,
      },
      {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        panelClass: 'pos-top-center',
        offsetY: -12,
      },
    ];
  }
}
