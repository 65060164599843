import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConfig } from '@ik/shared-data/app.config';

@Component({
  selector: 'ik-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  appVersion = environment.appVersion;
  envName = environment.envName;
  currentYear: number = 2021;

  constructor(public appConfig: AppConfig) {}

  ngOnInit() {
    this.currentYear = new Date().getFullYear();
  }
}
