import { Injectable } from '@angular/core';
import { debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { SocketService } from '../services/socket.service';
import { AuthQuery } from '../auth/state/auth.query';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class TestQueueService {
  public testQueueSubject = new BehaviorSubject<any>(null);

  private testQueueSubscription: Subscription;
  private disconnectTimeout: any;
  private isDisconnected: boolean = true;
  private socketHasConnected: boolean = false;

  constructor(private authQuery: AuthQuery, private socketService: SocketService) {}

  public start() {
    this.subscribeEvents();
    return this.authQuery.loggedIn$.pipe(
      debounceTime(100),
      distinctUntilChanged(),
      tap(async loggedIn => {
        if (!loggedIn) {
          this.socketService.disconnect();
        }
      })
    );
  }

  connectTestQueue(): Observable<any> {
    return this.testQueueSubject.asObservable();
  }

  setTestQueue(data) {
    this.testQueueSubject.next(data);
  }

  private subscribeEvents() {
    this.socketService.connected$.subscribe(connected => {
      if (!connected) {
        if (!this.disconnectTimeout && this.socketHasConnected === true) {
          this.disconnectTimeout = setTimeout(() => {
            this.stop();
          }, 30 * 1000);
        } else {
          this.stop();
        }
      } else {
        if (this.disconnectTimeout) {
          clearTimeout(this.disconnectTimeout);
          this.disconnectTimeout = null;
        }

        this.socketHasConnected = true;
        this.processSocketConnection();
      }
    });
  }

  private stop() {
    this.testQueueSubscription?.unsubscribe();
    this.isDisconnected = true;
  }

  private processSocketConnection() {
    if (this.isDisconnected === false) {
      return;
    }

    this.isDisconnected = false;
    this.testQueueSubscription = this.socketService.on(['test-queue:send']).subscribe(data => {
      this.testQueueSubject.next(data);
    });
  }
}
