import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const adminUsersTableName = 'AdminUsersTable';
export const adminUsersTable = TableReducer.reducerFactory(adminUsersTableName);

export function adminUsersTableReducer(state, action) {
  return adminUsersTable.reducer(state, action);
}

export const adminUsersTableSelectors = TableSelectors.selectorsFactory('admin-users', adminUsersTableName);
