import { Injectable } from '@angular/core';
import { merge, Observable, ReplaySubject } from 'rxjs';
import io from 'socket.io-client';
import { DesktopApi } from '../helpers/desktop.api';

@Injectable({
  providedIn: 'root'
})
export class SocketService {
  connected$ = new ReplaySubject<boolean>(1);
  io: any;

  constructor() {
    this.io = io(DesktopApi.socketServer, {
      autoConnect: false,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 30 * 1000
    });

    this.on(['connect', 'disconnect', 'connect_error']).subscribe(res => {
      this.connected$.next(this.io.connected);
    });

    this.on(['error']).subscribe(res => {
      console.log('socket error: ', JSON.stringify(res));
    });
  }

  connect() {
    this.io.connect();
  }

  disconnect() {
    this.io.disconnect();
  }

  emit(event, data = null) {
    this.io.emit(event, data);
  }

  on<T = any>(events: string[]): Observable<T> {
    return merge(
      ...events.map(event => {
        return this.prepareObservable(event);
      })
    );
  }

  private prepareObservable<T = any>(event): Observable<T> {
    return new Observable(observer => {
      this.io.on(event, eventResult => {
        observer.next(eventResult);
      });
      return () => this.io.off(event);
    });
  }
}
