import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const testsTableName = 'TestsTable';
export const testsTable = TableReducer.reducerFactory(testsTableName);

export function testsTableReducer(state, action) {
  return testsTable.reducer(state, action);
}

export const testsTableSelectors = TableSelectors.selectorsFactory('tests', testsTableName);
