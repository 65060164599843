export const isoDateFormat = 'YYYY-MM-DD';

export function isEmpty(value: any) {
  return value === '' || value === null || value === undefined;
}

export function getFormattedDate() {
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-indexed, add 1
  const day = now.getDate().toString().padStart(2, '0');
  const hour = now.getHours().toString().padStart(2, '0');
  const minute = now.getMinutes().toString().padStart(2, '0');
  const second = now.getSeconds().toString().padStart(2, '0');

  const filename = `csv_reports_export_${year}${month}${day}${hour}${minute}${second}.csv`;
  return filename;
}

export function extractFilename(contentDisposition: string | null): string | null {
  if (!contentDisposition) return null;

  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/i;
  const matches = filenameRegex.exec(contentDisposition);
  if (matches != null && matches[1]) {
    return matches[1].replace(/['"]/g, '');
  }
  return null;
}
