<header class="dialog-header">
  <h2 class="dialog-header-title">{{ data?.title }}</h2>
  <a (click)="close($event)" class="dialog-header-close" href="">
    <ik-icon name="close"></ik-icon>
  </a>
</header>
<section class="dialog-content">
  {{ data?.text }}
  <br>
  <span class="note" *ngIf="data?.note"> {{ data?.note }}</span>
</section>

<footer class="dialog-footer end">
  <button (click)="confirm($event)" ik-button>{{ 'confirmModal.confirmButton.text' | translate }}</button>
  <button (click)="close($event)" ik-button="secondary">{{ 'common.form.cancel' | translate }}</button>
</footer>
