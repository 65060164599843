import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { localTestsTable } from './local-tests-table.ngrx';
import { MainEquipmentQuery } from '../../state/main-equipment.query';
import { LocalTestsService } from '../../services/local-tests.service';

@Injectable()
export class LocalTestsTableEffects {
  onUpdateActive$ = createEffect(() =>
    this.mainEquipmentQuery.tests$.pipe(
      debounceTime(500),
      map(() => new localTestsTable.actions.LoadData())
    )
  );

  loadData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(localTestsTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.localTestsService.getAllTests()),
      map(res => {
        return new localTestsTable.actions.LoadDataSuccess(res);
      })
    );
  });

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(localTestsTable.actionTypes.changeSort),
      map(() => {
        return new localTestsTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(localTestsTable.actionTypes.changePage),
      map(() => {
        return new localTestsTable.actions.LoadData();
      })
    );
  });

  filtersChange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(localTestsTable.actionTypes.changeFilters),
      map(() => {
        return new localTestsTable.actions.LoadData();
      })
    );
  });

  constructor(
    private actions$: Actions,
    private localTestsService: LocalTestsService,
    private mainEquipmentQuery: MainEquipmentQuery
  ) {}
}
