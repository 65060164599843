import { Injectable } from '@angular/core';
import { BaseApi } from '@ik/shared-data/base.api';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class OfflineApi extends BaseApi {
  base = `${environment.desktopApp}/offline-api`;

  login = `${this.base}/auth/login`;
  company = `${this.base}/company`;
  equipments = `${this.base}/equipment`;
  testFiles = `${this.base}/testfile`;
  reports = `${this.base}/report`;
  reportTemplates = `${this.base}/report-template`;
  reportLogo = `${this.base}/report-logo`;
  systemConfig = `${this.base}/system-config`;
  scannerParameter = `${this.base}/scanner_parameter`;
}
