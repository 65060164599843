import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface CommandLog {
  dateTime: any;
  hostname: string;
  type: string;
  address: string;
  method: string;
  message: string;
}

export interface CommandLogState {
  log: CommandLog[];
}

export function createInitialState(): CommandLogState {
  return {
    log: []
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'commandlog' })
export class CommandLogStore extends Store<CommandLogState> {

  buffer: CommandLog[];

  constructor() {
    super(createInitialState());
    this.buffer = [];
  }

  addLog(msgObj: any) {
    const newLog: CommandLog = {
      dateTime: msgObj.dateTime,
      hostname: msgObj.hostname,
      type: msgObj.type,
      address: msgObj.address,
      method: msgObj.method,
      message: msgObj.message,
    };

    this.buffer.push(newLog);
    this.update({ log: this.buffer});
  }

  reset(): void {
    this.buffer = [];
    this.update({ log: this.buffer});
  }
}
