import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const usersTableName = 'UsersTable';
export const usersTable = TableReducer.reducerFactory(usersTableName);

export function usersTableReducer(state, action) {
  return usersTable.reducer(state, action);
}

export const usersTableSelectors = TableSelectors.selectorsFactory('users', usersTableName);
