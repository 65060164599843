import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthStore } from './auth.store';
import { finalize, map, tap } from 'rxjs/operators';
import { BaseAuthService } from '@ik/shared-data/base-services/base-auth.service';
import { CloudApi } from '@app/helpers/cloud.api';
import { SignUpResponse, User } from '../../../typings/auth';
import { Observable } from 'rxjs';
import { resetStores, setLoading } from '@datorama/akita';
import { Profile } from '../../../typings/user';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseAuthService {
  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private authStore: AuthStore,
  ) {
    super();
  }

  login(email: string, password: string) {
    return this.http.post<User>(this.cloudApi.login, { user: { email, password } }, { observe: 'response' }).pipe(
      setLoading(this.authStore),
      map(response => {
        const token = response.headers.get('authorization');
        return {
          user: response.body,
          token: token.replace('Bearer ', '')
        };
      }),
      tap(data => {
        this.authStore.update(data);
      })
    );
  }

  signup(user: any, trial_version: boolean): Observable<SignUpResponse> {
    const data = { ...user, trial_version };
    return this.http.post<SignUpResponse>(this.cloudApi.signup, { user: data });
  }

  resend(email: string) {
    return this.http.post(this.cloudApi.confirmation, { user: { email } });
  }

  logout() {
    return this.http.delete<any>(this.cloudApi.logout, {}).pipe(
      finalize(() => {
        this.authStore.update({ token: null, user: null });
        resetStores();
      })
    );
  }

  loadProfile() {
    return this.http.get<Profile>(this.cloudApi.profile, {}).pipe(tap(user => this.authStore.update({ user })));
  }

  updateProfile(data) {
    return this.http.patch<Profile>(this.cloudApi.profile, data).pipe(tap(user => this.authStore.update({ user })));
  }

  accept(confirmationToken: string) {
    return this.http.get(this.cloudApi.confirmation, { params: { confirmation_token: confirmationToken } });
  }

  resetPassword(email: string) {
    return this.http.post(this.cloudApi.resetPassword, { user: { email } });
  }

  validateResetToken(token: string) {
    return this.http.get(this.cloudApi.resetPasswordCheck, {
      params: { reset_password_token: token }
    });
  }

  applyNewPassword(token: string, password: string) {
    return this.http.patch(this.cloudApi.resetPassword, {
      user: {
        reset_password_token: token,
        password,
        password_confirmation: password
      }
    });
  }
}
