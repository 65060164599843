<label #label [attr.for]="inputId" class="ik-checkbox-layout">
  <div
    [class.ik-checkbox-inner-container-no-side-margin]="!checkboxLabel.textContent || !checkboxLabel.textContent.trim()"
    class="ik-checkbox-inner-container"
  >
    <input
      #input
      (change)="_onInteractionEvent($event)"
      (click)="_onInputClick($event)"
      [attr.aria-checked]="_getAriaChecked()"
      [attr.aria-label]="ariaLabel || null"
      [attr.aria-labelledby]="ariaLabelledby"
      [attr.name]="name"
      [attr.value]="value"
      [checked]="checked"
      [disabled]="disabled"
      [id]="inputId"
      [indeterminate]="indeterminate"
      [required]="required"
      [tabIndex]="tabIndex"
      class="ik-checkbox-input cdk-visually-hidden"
      type="checkbox"
    />
    <div class="ik-checkbox-frame"></div>
    <div class="ik-checkbox-background">
      <svg class="ik-checkbox-checkmark" focusable="false" version="1.1" viewBox="0 0 24 24" xml:space="preserve">
        <path class="ik-checkbox-checkmark-path" d="M4.1,12.7 9,17.6 20.3,6.3" fill="none" stroke="#7d7e81" />
      </svg>
      <!-- Element for rendering the indeterminate state checkbox. -->
      <div class="ik-checkbox-mixedmark"></div>
    </div>
  </div>
  <span #checkboxLabel (cdkObserveContent)="_onLabelTextChange()" class="ik-checkbox-label">
    <!-- Add an invisible span so JAWS can read the label -->
    <span style="display:none">&nbsp;</span>
    <ng-content></ng-content>
  </span>
</label>
