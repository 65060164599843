<div (click)="setActive($event)" *ngIf="!active" [attr.id]="id" class="lds-ripple">
  <div></div>
  <div></div>
</div>
<div *ngIf="active" class="text">
  <span (click)="skip($event)" class="cross"></span>
  <ng-container *ngIf="displayType === 'string'">{{ text }}</ng-container>
  <div *ngIf="displayType === 'template'" class="text-container">
    <ng-container *ngTemplateOutlet="text"></ng-container>
  </div>
  <div class="actions">
    <div class="back-action">
      <a (click)="back($event)" *ngIf="hasBack" href="">{{ 'onboarding.backButton.text' | translate }}</a>
    </div>
    <div class="next-action">
      <a (click)="next($event)" *ngIf="hasNext" [small]="true" class="next" href="" ik-button>{{ 'onboarding.nextButton.text' | translate }}</a>
      <a (click)="finish($event)" *ngIf="!hasNext" [small]="true" class="next" href="" ik-button>{{ 'onboarding.finishButton.text' | translate }}</a>
    </div>
  </div>
  <div class="opt-out">
    <a href="" (click)="hideNotifications($event)">{{ 'onboarding.hideTipsButton.text' | translate }}</a>
  </div>
</div>
