import { Injectable } from '@angular/core';
import { InstrumentsStore } from './instruments.store';
import { HttpClient } from '@angular/common/http';
import { BaseApi } from '@ik/shared-data/base.api';
import { switchMap, tap } from 'rxjs/operators';
import { EquipmentAttributes } from '../../../typings/reports';
import { PaginatedData } from '../../../typings/common';
import { Observable } from 'rxjs';
import { Equipment } from '../../../typings/equipment';
import { DesktopQuery } from '../../state/desktop.query';
import { setLoading } from '@datorama/akita';
import { MainEquipmentService } from '../../state/main-equipment.service';
import { EquipmentState } from '../../state/main-equipment.store';
import { ActiveEquipmentService } from '../../state/active-equipment.service';

@Injectable({ providedIn: 'root' })
export class InstrumentsService {
  constructor(
    private baseApi: BaseApi,
    protected store: InstrumentsStore,
    private desktopQuery: DesktopQuery,
    private http: HttpClient,
    private mainEquipmentService: MainEquipmentService,
    private activeEquipmentService: ActiveEquipmentService
  ) {}

  load() {
    return this.http.get<PaginatedData<EquipmentAttributes>>(this.baseApi.equipments).pipe(
      setLoading(this.store),
      tap(equipments => {
        this.store.set(equipments.data);
        this.mainEquipmentService.checkConnectionEnabled(equipments.data);
        this.activeEquipmentService.checkConnectionEnabled(equipments.data);
      })
    );
  }

  remove(id: number) {
    return this.http.delete(`${this.baseApi.equipments}/${id}`).pipe(
      setLoading(this.store),
      tap(() => this.store.remove(id)),
      switchMap(() => this.load())
    );
  }

  update(id: number, data) {
    return this.http
      .patch<EquipmentAttributes>(`${this.baseApi.equipments}/${id}`, { equipment: data })
      .pipe(
        setLoading(this.store),
        tap(result => this.store.update(id, result)),
        switchMap(() => this.load())
      );
  }

  create(data, equipmentData: EquipmentState): Observable<Equipment> {
    return this.desktopQuery.hostname$.pipe(
      setLoading(this.store),
      switchMap(host => {
        return this.http.post<Equipment>(this.baseApi.equipments, {
          firmware: equipmentData.info.firmware,
          manufacturer: equipmentData.info.manufacturer,
          equipment_type: data.equipmentModel,
          model_number: equipmentData.info.model_number,
          serial_number: equipmentData.info.serial_number,
          calibration_date: equipmentData.info.calibrationDate,
          scanner_status: equipmentData.info.scannerStatus,
          workstations_attributes: [
            {
              name: host,
              address: data.address,
              connection_type: data.connectionType
            }
          ]
        });
      })
    );
  }
}
