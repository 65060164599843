import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { AdminCompaniesModule } from './admin-companies-store/admin-companies.module';
import { AdminUsersStoreModule } from './admin-users-store/admin-users-store.module';
import { LocalTestsStoreModule } from './local-tests-store/local-tests-store.module';
import { ReportsStoreModule } from './reports-store/reports-store.module';
import { SnackbarStoreModule } from './snackbar-store/snackbar-store.module';
import { TestsStoreModule } from './tests-store/tests-store.module';
import { UsersStoreModule } from './users-store/users-store.module';

const metaReducers: Array<MetaReducer<any, any>> = [
  // ...(!environment.production ? [] : []),
];

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true
        }
      }
    ),
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
    EffectsModule.forRoot([]),
    SnackbarStoreModule,
    ReportsStoreModule,
    UsersStoreModule,
    AdminCompaniesModule,
    AdminUsersStoreModule,
    TestsStoreModule,
    LocalTestsStoreModule
  ],
  declarations: []
})
export class RootStoreModule {}
