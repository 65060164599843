<header class="dialog-header">
  <h2 class="dialog-header-title">{{ data?.title }}</h2>
  <a (click)="close($event)" class="dialog-header-close" href="">
    <ik-icon name="close"></ik-icon>
  </a>
</header>
<section class="dialog-content" [innerHTML]="data?.text">
</section>

<footer class="dialog-footer end">
  <button (click)="close($event)" ik-button="secondary">{{ 'common.form.close' | translate }}</button>
</footer>
