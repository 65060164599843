import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

const defaultControlPanelData: ControlPanelState = {
  setting: {
    isLocalModeOn: false,
  },
  barcodeSetting: {
    isShowModel: false,
    isShowSerial: false,
    scanFirstField: '',
    isConcatenated: false,
    customDelimiter: '',
  },
};

export interface ControlPanelState {
  setting: ControlPanelSetting;
  barcodeSetting: BarcodeSetting;
}

export interface ControlPanelSetting {
  isLocalModeOn: boolean;
}

export interface BarcodeSetting {
  isShowModel: boolean;
  isShowSerial: boolean;
  scanFirstField: string;
  isConcatenated: boolean;
  customDelimiter: string;
}

export interface SaveLocation {
  testFile: string;
  testReport: string;
}

function createInitialState(): ControlPanelState {
  return defaultControlPanelData;
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'controlPanel' })
export class ControlPanelStore extends Store<ControlPanelState> {
  private controlPanelLocalStorageKey = 'controlPanelStorage';
  private _controlPanelStorage: ControlPanelState;

  private get controlPanelStorage(): ControlPanelState {
    if (!this._controlPanelStorage) {
      this._controlPanelStorage = this.getControlPanelStorage();
    }

    return this._controlPanelStorage;
  }

  constructor() {
    super(createInitialState());

    this.update(this.getControlPanelStorage());
  }

  private getControlPanelStorage(): any {
    const controlPanelData = localStorage.getItem(this.controlPanelLocalStorageKey);

    if (!controlPanelData) {
      this.setControlPanelStorage(defaultControlPanelData);
      this._controlPanelStorage = defaultControlPanelData;
    }

    return JSON.parse(controlPanelData);
  }

  private setControlPanelStorage(data: ControlPanelState): any {
    localStorage.setItem(this.controlPanelLocalStorageKey, JSON.stringify(data));
    this.update(data);
  }

  updateSetting(setting: ControlPanelSetting) {
    this.controlPanelStorage.setting = setting;

    this.setControlPanelStorage(this.controlPanelStorage);
  }

  updateBarcodeSetting(barcodeSetting: BarcodeSetting) {
    this.controlPanelStorage.barcodeSetting = barcodeSetting;

    this.setControlPanelStorage(this.controlPanelStorage);
  }

  reset(): void {
    const barcodeSetting = this.getValue().barcodeSetting;
    const controlPanelData: ControlPanelState = {
      setting: defaultControlPanelData.setting,
      barcodeSetting: barcodeSetting,
    };

    this.setControlPanelStorage(controlPanelData);
  }
}
