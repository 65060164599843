import { Component, OnDestroy, OnInit } from '@angular/core';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { OnboardingService } from '../onboarding/onboarding.service';
import { DesktopQuery } from '../../state/desktop.query';
import { InstrumentsQuery } from '../../equipment/state/instruments.query';
import { MainEquipmentQuery } from 'src/app/state/main-equipment.query';
import { Info } from '../../state/main-equipment.store';
import { AppConfig } from '@ik/shared-data/app.config';

@UntilDestroy()
@Component({
  selector: 'ik-header-equipment-indicator',
  templateUrl: './header-equipment-indicator.component.html',
  styleUrls: ['./header-equipment-indicator.component.scss']
})
export class HeaderEquipmentIndicatorComponent implements OnInit, OnDestroy {
  connected = false;
  equipmentsCount = 0;
  activeEquipment: Info;

  constructor(
    public appConfig: AppConfig,
    private onboarding: OnboardingService,
    private desktopQuery: DesktopQuery,
    private instrumentsQuery: InstrumentsQuery,
    private mainEquipmentQuery: MainEquipmentQuery
  ) {}

  ngOnInit() {
    this.mainEquipmentQuery.info$.pipe(untilDestroyed(this)).subscribe(info => {
      this.activeEquipment = info;
    });
    this.instrumentsQuery
      .selectCount()
      .pipe(untilDestroyed(this))
      .subscribe(count => {
        this.equipmentsCount = count;
      });
    this.desktopQuery.connected$.pipe(untilDestroyed(this)).subscribe(connected => {
      this.connected = connected;
    });
  }

  public ngOnDestroy(): void {}

  public resetTips($event = null) {
    if ($event) {
      $event.preventDefault();
    }
    this.onboarding.resetAll();
  }
}
