import { Component, Inject } from '@angular/core';
import { DATA_TOKEN } from '../../helpers/tokens';
import { ElectronService } from '@offline-app/services/electron.service';
import { OverlayService } from '@app/services/overlay.service';

@Component({
  selector: 'ik-alert-overlay',
  templateUrl: './alert-overlay.component.html',
  styleUrls: ['./alert-overlay.component.scss'],
})
export class AlertOverlayComponent {
  public data: any = {
    content: 'Exporting CSV file...',
    loading: true,
  };

  constructor(
    @Inject(DATA_TOKEN) data: any,
    private electronService: ElectronService,
    private overlayService: OverlayService
  ) {
    this.data = data;
  }

  onClickOpenFile() {
    this.electronService.openFile(this.data.filePath);
  }

  onClickOpenFolder() {
    this.electronService.openFolder(this.data.folderPath);
  }

  onClickClose() {
    this.overlayService.close();
  }
}
