import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { IkButtonModule } from '../button/ik-button.module';
import { IkFormsModule } from '../forms/ik-forms.module';
import { OnboardingDirective } from './onboarding.directive';
import { OnboardingComponent } from './onboarding/onboarding.component';

@NgModule({
  declarations: [OnboardingDirective, OnboardingComponent],
  exports: [OnboardingDirective, OnboardingComponent],
  imports: [CommonModule, IkButtonModule, IkFormsModule, TranslateModule],
})
export class OnboardingModule {}
