import { Injectable } from '@angular/core';
import { Instrument } from './instrument.model';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

export interface InstrumentsState extends EntityState<Instrument> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'instruments' })
export class InstrumentsStore extends EntityStore<InstrumentsState> {
  constructor() {
    super();
  }
}
