import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { untilDestroyed, UntilDestroy } from '@ngneat/until-destroy';
import { ObservableInput } from 'observable-input/lib';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { startWith } from 'rxjs/internal/operators/startWith';
import { map, switchMap } from 'rxjs/operators';
import { PermissionService } from '../../services/permission.service';

@UntilDestroy()
@Directive({
  selector: '[ikPermission]',
})
export class PermissionDirective implements OnDestroy, OnInit {
  @Input()
  ikPermission: any;
  @Input()
  @ObservableInput()
  ikPermissionOnly: Observable<string[] | string>;
  @Input()
  @ObservableInput()
  ikPermissionExcept: Observable<string[] | string>;
  private destroy$ = new Subject<void>();
  private role: string = undefined;
  private attached = false;
  private processSubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private store: Store<any>,
    private permissionService: PermissionService,
    private viewContainer: ViewContainerRef
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    combineLatest([
      this.ikPermissionOnly.pipe(startWith<string, null>(null), map(this.makeArray)),
      this.ikPermissionExcept.pipe(startWith<string, null>(null), map(this.makeArray)),
    ])
      .pipe(
        switchMap(([only, except]) => this.permissionService.checkPermission(only, except)),
        untilDestroyed(this)
      )
      .subscribe((result) => {
        if (result) {
          this.attach();
        } else {
          this.detach();
        }
      });
  }

  private makeArray(value: string | string[]): string[] | null {
    return value ? [].concat(value) : null;
  }

  private attach() {
    if (this.attached) {
      return;
    }
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.attached = true;
  }

  private detach() {
    if (!this.attached) {
      return;
    }
    this.viewContainer.clear();
    this.attached = false;
  }
}
