<svg version="1.1" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <symbol height="13" id="arrow-down" viewBox="0 0 13 13" width="13">
      <path
        d="m6.5,10.502l-6.5,-6.488l1.517,-1.514l4.983,4.974l4.983,-4.974l1.517,1.514l-6.5,6.488z"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol height="16" id="close" viewBox="0 0 16 16" width="16">
      <path
        d="M16 14.4L14.4 16 8 9.6 1.6 16 0 14.4 6.4 8 0 1.6 1.6 0 8 6.4 14.4 0 16 1.6 9.6 8l6.4 6.4z"
        fill="#FFF"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="menu" viewBox="0 0 512 512">
      <path
        d="M491.318 235.318H20.682C9.26 235.318 0 244.577 0 256s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 78.439H20.682C9.26 78.439 0 87.699 0 99.121c0 11.422 9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.26 20.682-20.682 0-11.422-9.259-20.682-20.682-20.682zM491.318 392.197H20.682C9.26 392.197 0 401.456 0 412.879s9.26 20.682 20.682 20.682h470.636c11.423 0 20.682-9.259 20.682-20.682s-9.259-20.682-20.682-20.682z"
      />
    </symbol>

    <filter height="130%" id="cautionShadow">
      <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
      <!-- stdDeviation is how much to blur -->
      <feOffset dx="0" dy="2" result="offsetblur" />
      <!-- how much to offset -->
      <feComponentTransfer>
        <feFuncA slope="0.2" type="linear" />
        <!-- slope is the opacity of the shadow -->
      </feComponentTransfer>
      <feMerge>
        <feMergeNode />
        <!-- this contains the offset blurred image -->
        <feMergeNode in="SourceGraphic" />
        <!-- this contains the element that the filter is applied to -->
      </feMerge>
    </filter>
    <symbol id="caution" viewBox="0 0 144.07 114.44">
      <g>
        <g>
          <path
            d="M1.13 103.65l65-100.46a7 7 0 0 1 11.73 0l65 100.46a7 7 0 0 1-5.87 10.79H7a7 7 0 0 1-5.87-10.79z"
            fill="#ffffff"
            style="filter:url(#cautionShadow); fill: #fff"
          />
          <path
            d="M72 12.26l-59.5 91.92h119zm.64 79.56c-.65-.14 1.14-22.63.51-23.34s-11.4-.1-12.43-1.39 10-30.79 10.63-30.56-1 22.81-.5 23.34 11.54 0 12.43 1.39S73.34 92 72.68 91.82z"
          />
        </g>
      </g>
    </symbol>
    <symbol id="fail-stop" viewBox="0 0 38 38">
      <path
        d="M19 38C8.55 38 0 29.45 0 19S8.55 0 19 0s19 8.55 19 19-8.55 19-19 19zm9.5-25.84L25.84 9.5 19 16.34 12.16 9.5 9.5 12.16 16.34 19 9.5 25.84l2.66 2.66L19 21.66l6.84 6.84 2.66-2.66L21.66 19l6.84-6.84z"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="caution-icon" viewBox="0 0 92.39 92.39">
      <path
        d="M65.33 0H27.06L0 27.06v38.27l27.06 27.06h38.27l27.06-27.06V27.06zM33.47 81.19C32.76 80.73 45 54.94 44.61 53.8s-13.44-5.26-14.07-7.25S56.23 14.63 56.92 15.2 45.45 41.74 45.78 42.59s13.67 5.15 14.08 7.26-25.68 31.8-26.39 31.34z"
        data-name="Layer 1"
        fill="gray"
      />
    </symbol>
    <symbol id="export" viewBox="0 0 12 16">
      <path
        d="M6.001 12.001l5.656-5.655-1.41-1.411L6.99 8.192V0H5.001v8.173l-3.244-3.24L.344 6.346zM0 14h12v2H0z"
        fill="#B32028"
      />
    </symbol>
    <symbol id="search" viewBox="0 0 26.22 26.26">
      <g>
        <path d="M25.93,26a1,1,0,0,1-1.42,0l-5.46-5.51a1,1,0,0,1,1.41-1.41l5.47,5.5A1,1,0,0,1,25.93,26Z" />
        <path
          d="M20.48,3.51a12,12,0,1,0,0,17A12,12,0,0,0,20.48,3.51ZM19.07,19.07a10,10,0,1,1,0-14.15A10,10,0,0,1,19.07,19.07Z"
        />
      </g>
    </symbol>
    <symbol id="cross" viewBox="0 0 26.22 26.26">
      <path
        clip-rule="evenodd"
        d="M11.75.624C5.51.624.5 5.636.5 11.874s5.011 11.25 11.25 11.25S23 18.112 23 11.874 17.989.624 11.75.624zm1.636 11.455l3.835 3.835c.102.103.154.256.154.358s-.053.256-.154.358l-.715.715a.552.552 0 0 1-.358.154.463.463 0 0 1-.358-.154l-3.835-3.835a.245.245 0 0 0-.357 0l-3.836 3.835a.55.55 0 0 1-.36.155.46.46 0 0 1-.357-.154l-.715-.715c-.103-.103-.154-.256-.154-.358s.051-.256.154-.358l3.834-3.835a.245.245 0 0 0 0-.357L6.33 7.885a.547.547 0 0 1-.154-.357.55.55 0 0 1 .154-.358l.715-.715a.551.551 0 0 1 .357-.155c.103 0 .257.051.358.154l3.836 3.835a.246.246 0 0 0 .357 0l3.835-3.835a.562.562 0 0 1 .36-.154c.153 0 .255.051.358.154l.715.715a.557.557 0 0 1 .154.358.556.556 0 0 1-.154.357l-3.835 3.836c-.102.052-.102.257 0 .359z"
        fill="#B32028"
        fill-rule="evenodd"
      />
    </symbol>
    <symbol id="checkmark" viewBox="0 0 16 16">
      <path class="a" d="M11.375,3.375a8,8,0,1,0,8,8A8.024,8.024,0,0,0,11.375,3.375ZM9.733,15.64l-4.1-4.1,1.15-1.15,2.954,2.954L15.971,7.11l1.15,1.15Z" transform="translate(-3.375 -3.375)"/>
    </symbol>
    <symbol id="checkmark-circle" viewBox="0 0 32 32">
      <path d="M263.073,250.923a1.251,1.251,0,0,1,0,1.768l-8.386,8.386a1.251,1.251,0,0,1-1.768,0l-3.992-3.992a1.25,1.25,0,0,1,1.768-1.768l3.108,3.108,7.5-7.5A1.251,1.251,0,0,1,263.073,250.923ZM272,256a16,16,0,1,1-16-16A15.991,15.991,0,0,1,272,256Zm-2.5,0A13.5,13.5,0,1,0,256,269.5,13.493,13.493,0,0,0,269.5,256Z" transform="translate(-240 -240)"/>
    </symbol>
    <symbol id="verified" viewBox="0 0 512 512">
      <g>
        <g>
          <path
            d="M437.019,74.98C388.667,26.629,324.38,0,256,0C187.619,0,123.331,26.629,74.98,74.98C26.628,123.332,0,187.62,0,256
            s26.628,132.667,74.98,181.019C123.332,485.371,187.619,512,256,512c68.38,0,132.667-26.629,181.019-74.981
            C485.371,388.667,512,324.38,512,256S485.371,123.333,437.019,74.98z M256,482C131.383,482,30,380.617,30,256S131.383,30,256,30
            s226,101.383,226,226S380.617,482,256,482z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M378.305,173.859c-5.857-5.856-15.355-5.856-21.212,0.001L224.634,306.319l-69.727-69.727
            c-5.857-5.857-15.355-5.857-21.213,0c-5.858,5.857-5.858,15.355,0,21.213l80.333,80.333c2.929,2.929,6.768,4.393,10.606,4.393
            c3.838,0,7.678-1.465,10.606-4.393l143.066-143.066C384.163,189.215,384.163,179.717,378.305,173.859z"
          />
        </g>
      </g>
    </symbol>
    <symbol id="interlock_closed">
      <defs>
        <path
          d="M21.223 17.918H7.266V9.195A5.212 5.212 0 0 1 12.5 3.961a5.212 5.212 0 0 1 5.234 5.234v4.188c0 1.163.813 2.209 1.744 2.209.93 0 1.744-.93 1.744-2.209V9.195c0-4.769-3.954-8.724-8.723-8.724S3.776 4.426 3.776 9.195v8.723c-1.978 0-3.489 1.513-3.489 3.489v11.631c0 1.978 1.512 3.489 3.489 3.489h17.446c1.978 0 3.49-1.512 3.49-3.489V21.407c.001-1.86-1.512-3.489-3.489-3.489zm-8.141 9.188v3.024c0 .349-.233.581-.582.581s-.582-.232-.582-.581v-3.024c-.698-.232-1.163-.93-1.163-1.628 0-.931.814-1.745 1.745-1.745.93 0 1.744.814 1.744 1.745 0 .815-.465 1.396-1.162 1.628z"
          id="interlock_a"
        />
        <path d="M.719.531h3.469v9.063H.719z" id="interlock_b" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <use fill="#D8D8D8" transform="translate(6)" xlink:href="#interlock_a" />
        <use fill="#D8D8D8" transform="translate(23 10)" xlink:href="#interlock_b" />
      </g>
    </symbol>
    <symbol id="interlock_open">
      <defs>
        <path
          d="M21.223 17.918H7.266V9.195A5.212 5.212 0 0 1 12.5 3.961a5.212 5.212 0 0 1 5.234 5.234v4.188c0 1.163.813 2.209 1.744 2.209.93 0 1.744-.93 1.744-2.209V9.195c0-4.769-3.954-8.724-8.723-8.724S3.776 4.426 3.776 9.195v8.723c-1.978 0-3.489 1.513-3.489 3.489v11.631c0 1.978 1.512 3.489 3.489 3.489h17.446c1.978 0 3.49-1.512 3.49-3.489V21.407c.001-1.86-1.512-3.489-3.489-3.489zm-8.141 9.188v3.024c0 .349-.233.581-.582.581s-.582-.232-.582-.581v-3.024c-.698-.232-1.163-.93-1.163-1.628 0-.931.814-1.745 1.745-1.745.93 0 1.744.814 1.744 1.745 0 .815-.465 1.396-1.162 1.628z"
          id="interlock_a"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <use fill="#D8D8D8" transform="translate(6)" xlink:href="#interlock_a" />
      </g>
    </symbol>
    <symbol id="connect" viewBox="208.306 109.362 29.75 24.833">
      <path
        d="M234.4 125.479l-4.771 6.039a4.536 4.536 0 0 1-6.362.746l-4.313-3.409a1.361 1.361 0 0 1-.224-1.909l8.704-11.015a1.36 1.36 0 0 1 1.908-.223l4.313 3.409a4.535 4.535 0 0 1 .745 6.362zm-15.165 1.867a.714.714 0 0 0 .117 1.002l4.313 3.409a3.888 3.888 0 0 0 5.456-.64l4.772-6.039a3.888 3.888 0 0 0-.641-5.456l-4.313-3.408a.714.714 0 0 0-1.002.117l-8.702 11.015z"
        stroke="#B32028"
      />
      <path
        d="M228.898 126.047a1.923 1.923 0 0 1-2.702.312c-.833-.659-.978-1.872-.321-2.704s1.868-.972 2.701-.312.977 1.873.322 2.704zm-2.667-2.11a1.476 1.476 0 0 0 .244 2.068 1.472 1.472 0 0 0 2.066-.238 1.475 1.475 0 0 0-.245-2.068 1.469 1.469 0 0 0-2.065.238z"
        stroke="#B32028"
        stroke-width=".702"
      />
      <path
        d="M235.206 127.937l-3.001 3.797a.322.322 0 0 1-.453.053l-1.691-1.337a.322.322 0 0 1-.053-.453l3-3.797a.32.32 0 0 1 .452-.053l1.692 1.337c.141.11.164.313.054.453zm-3.307 3.144l2.602-3.291-1.187-.938-2.601 3.291 1.186.938zM223.782 111.381l4.35 3.363a1.36 1.36 0 0 1 .244 1.906l-8.588 11.105a1.36 1.36 0 0 1-1.906.244l-4.35-3.364a4.535 4.535 0 0 1-.811-6.354l4.708-6.089a4.535 4.535 0 0 1 6.353-.811zm4.084 4.874a.715.715 0 0 0-.128-1.001l-4.35-3.363a3.888 3.888 0 0 0-5.449.696l-4.708 6.089a3.887 3.887 0 0 0 .696 5.449l4.349 3.363a.715.715 0 0 0 1.001-.128l8.589-11.105z"
        stroke="#B32028"
      />
      <path
        d="M215.302 111.946l1.706 1.319c.14.109.166.311.057.453l-2.96 3.828a.323.323 0 0 1-.453.058l-1.706-1.319a.322.322 0 0 1-.058-.452l2.961-3.828a.323.323 0 0 1 .453-.059zm-2.706 4.026l1.196.924 2.566-3.318-1.196-.925-2.566 3.319z"
        stroke="#B32028"
      />
      <path
        d="M233.684 129.863l3.685 2.881M209.184 111.072l3.685 2.881"
        fill="#008F8A"
        stroke="#B32028"
        stroke-width="1.445"
      />
    </symbol>
    <symbol id="disconnect" viewBox="560.807 113.779 39.666 32.667">
      <path
        d="M596.27 137.244l-4.771 6.039a4.537 4.537 0 0 1-6.363.746l-4.313-3.409a1.361 1.361 0 0 1-.225-1.909l8.705-11.015a1.36 1.36 0 0 1 1.908-.223l4.313 3.409a4.535 4.535 0 0 1 .746 6.362zm-15.166 1.867a.714.714 0 0 0 .117 1.002l4.314 3.409a3.887 3.887 0 0 0 5.455-.64l4.773-6.039a3.888 3.888 0 0 0-.641-5.456l-4.313-3.408a.713.713 0 0 0-1.002.117l-8.703 11.015z"
        stroke="#696969"
      />
      <path
        d="M583.143 136.53a.322.322 0 0 1-.453.053l-4.732-3.74a.969.969 0 0 0-1.359.159l-.066.083a.97.97 0 0 0 .16 1.359l4.734 3.74c.139.11.162.313.053.453a.325.325 0 0 1-.455.053l-4.732-3.74a1.614 1.614 0 0 1-.264-2.265l.064-.083a1.616 1.616 0 0 1 2.266-.265l4.732 3.74c.14.111.163.314.052.453zM589.434 128.571a.322.322 0 0 1-.453.053l-4.734-3.74a.967.967 0 0 0-1.357.159l-.066.083a.968.968 0 0 0 .16 1.359l4.732 3.74a.323.323 0 0 1-.4.506l-4.732-3.74a1.616 1.616 0 0 1-.266-2.265l.066-.083a1.613 1.613 0 0 1 2.264-.266l4.732 3.74c.14.112.163.314.054.454z"
        stroke="#696969"
      />
      <path
        d="M590.768 137.812a1.924 1.924 0 0 1-2.703.312c-.832-.659-.977-1.872-.32-2.704s1.867-.972 2.701-.312c.832.66.976 1.873.322 2.704zm-2.668-2.11a1.477 1.477 0 0 0 .244 2.068 1.472 1.472 0 0 0 2.066-.238 1.474 1.474 0 0 0-2.31-1.83z"
        stroke="#696969"
        stroke-width=".702"
      />
      <path
        d="M597.076 139.702l-3.002 3.797a.322.322 0 0 1-.453.053l-1.691-1.337a.323.323 0 0 1-.053-.453l3-3.797a.322.322 0 0 1 .453-.053l1.691 1.337c.141.11.165.314.055.453zm-3.308 3.144l2.602-3.291-1.186-.938-2.602 3.291 1.186.938zM576.703 115.808l4.35 3.363c.594.459.703 1.314.244 1.906l-8.588 11.105a1.36 1.36 0 0 1-1.906.244l-4.35-3.364a4.534 4.534 0 0 1-.811-6.354l4.707-6.089a4.537 4.537 0 0 1 6.354-.811zm4.084 4.875a.715.715 0 0 0-.127-1.001l-4.35-3.363a3.888 3.888 0 0 0-5.449.696l-4.709 6.089a3.887 3.887 0 0 0 .697 5.449l4.348 3.363a.716.716 0 0 0 1.002-.128l8.588-11.105z"
        stroke="#696969"
      />
      <path
        d="M568.223 116.374l1.707 1.319a.325.325 0 0 1 .057.453l-2.961 3.828a.32.32 0 0 1-.451.058l-1.707-1.319a.322.322 0 0 1-.057-.452l2.961-3.828a.32.32 0 0 1 .451-.059zm-2.705 4.025l1.195.924 2.566-3.318-1.195-.925-2.566 3.319z"
        stroke="#696969"
      />
      <path
        d="M595.553 141.628l3.686 2.881M562.106 115.499l3.685 2.881"
        fill="#008F8A"
        stroke="#696969"
        stroke-width="1.445"
      />
    </symbol>
    <symbol id="barcode" viewBox="0 0 18 14.833">
      <rect fill="#666666" height="10.655" width="0.502" x="0.989" y="0.91" />
      <rect fill="#666666" height="10.655" width="0.999" x="6.329" y="0.826" />
      <rect fill="#666666" height="3.924" width="0.666" x="9.837" y="0.91" />
      <rect fill="#666666" height="10.655" width="0.666" x="3.762" y="0.91" />
      <rect fill="#666666" height="4.2" width="1.332" x="13.001" y="0.91" />
      <line fill="none" x1="8.803" x2="8.409" y1="6.051" y2="6.051" />
      <g opacity="0.9">
        <g>
          <g>
            <path
              d="M534,803.67c0,4.455-0.218,8.342-0.483,8.635c-0.266,0.295-90.067,0.535-198.104,0.535H240.59
				c-108.035,0-196.914-0.107-197.508-0.238c-0.596-0.133-1.08-4.477-1.08-8.932s0.219-8.34,0.482-8.635
				c0.266-0.295,90.068-0.535,198.104-0.535h94.823c108.036,0,196.915,0.107,197.509,0.24C533.514,794.872,534,799.215,534,803.67z"
              fill="#B21F29"
            />
          </g>
        </g>
      </g>
      <g id="Forma_1_20_">
        <g>
          <path
            d="M17.869,14.077l-2.564-2.564c0.637-0.775,1.021-1.77,1.021-2.852c0-2.483-2.017-4.499-4.498-4.499
			c-2.484,0-4.498,2.014-4.498,4.499c0,2.481,2.014,4.496,4.498,4.496c1.084,0,2.073-0.381,2.85-1.017l2.564,2.563
			c0.174,0.174,0.454,0.174,0.629,0C18.044,14.532,18.044,14.249,17.869,14.077z M11.826,12.26c-1.988,0-3.604-1.614-3.604-3.602
			c0-1.987,1.616-3.604,3.604-3.604c1.984,0,3.604,1.617,3.604,3.604C15.432,10.646,13.813,12.26,11.826,12.26z"
            fill="#B21F29"
          />
        </g>
      </g>
    </symbol>
  </defs>
</svg>
