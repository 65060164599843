<header>
  <a
    [width]="300"
    class="brand"
    ikOnboarding="logo"
    onboardingText="{{ 'header.logo.onboardingText' | translate }}"
    routerLink="/"
  >
    <img class="header-logo" src="/assets/images/WithStand-Logo.png" />
  </a>
  <ng-container *ngTemplateOutlet="tmpl"></ng-container>
  <div class="toggle-nav">
    <button (click)="toggleNav()" ik-button-icon>
      <ik-icon name="menu"></ik-icon>
    </button>
  </div>
</header>
<ng-template #mobileNav>
  <ik-navigation-overlay (close)="close()" [@transformPanel]="'showing'">
    <ng-container *ngTemplateOutlet="tmpl"></ng-container>
  </ik-navigation-overlay>
</ng-template>
<ng-template #tmpl>
  <nav *ikPermission="''; except: ['SuperAdmin', 'Guest']">
    <a href="" routerLink="/equipment/test" routerLinkActive="active">{{ 'header.nav.performTestsLink.text' | translate }}</a>
    <a
      [routerLinkActiveOptions]="{ exact: true }"
      [weightMultiplier]="1.1"
      href=""
      ikOnboarding="eqListLink"
      onboardingText="{{ 'header.nav.instrumentListLink.onboardingText' | translate }}"
      routerLink="/equipment"
      routerLinkActive="active"
      >{{ 'header.nav.instrumentListLink.text' | translate }}</a
    >
    <a href="" routerLink="/users" routerLinkActive="active">{{ 'common.users' | translate }}</a>
    <a
      [weightMultiplier]="1.1"
      href=""
      ikOnboarding="testsListLink"
      onboardingText="{{ 'header.nav.testFilesLink.onboardingText' | translate }}"
      routerLink="/tests"
      routerLinkActive="active"
      >{{ 'header.nav.testFilesLink.text' | translate }}</a
    >
    <a
      [weightMultiplier]="1.1"
      href=""
      ikOnboarding="reportsLink"
      onboardingText="{{ 'header.nav.reportsLink.onboardingText' | translate }}"
      routerLink="/reports"
      routerLinkActive="active"
      >{{ 'header.nav.reportsLink.text' | translate }}</a
    >
  </nav>
  <nav *ikPermission="''; only: ['SuperAdmin']">
    <a [routerLinkActiveOptions]="{ exact: true }" href="" routerLink="/admin" routerLinkActive="active">{{ 'header.nav.dashboardLink.text' | translate }}</a>
    <a href="" routerLink="/admin/companies" routerLinkActive="active">{{ 'header.nav.companiesLink.text' | translate }}</a>
    <a href="" routerLink="/admin/users" routerLinkActive="active">{{ 'common.users' | translate }}</a>
  </nav>
  <div class="spacer"></div>
  <ik-header-equipment-indicator *ikPermission="''; except: ['SuperAdmin', 'Guest']"></ik-header-equipment-indicator>
  <ik-user-panel #panel="userPanel" *ikPermission="''; except: ['Guest']">
    <a (click)="panel.close()" href="" routerLink="/my-account">{{ 'header.myAccountDropdown.myProfileLink.text' | translate }}</a>
    <a *ikPermission="''; only: ['Owner']" href="" (click)="panel.close()" routerLink="/company-profile">{{ 'header.myAccountDropdown.companyProfileLink.text' | translate }}</a>
    <a (click)="panel.close()" href="" routerLink="/control-panel">{{ 'header.myAccountDropdown.controlPanelLink.text' | translate }}</a>
    <a (click)="panel.close()" href="" routerLink="/user-guide">{{ 'header.myAccountDropdown.userGuideLink.text' | translate }}</a>
    <a (click)="logout($event)" href="">{{ 'header.myAccountDropdown.logoutLink.text' | translate }}</a>
  </ik-user-panel>
  <ng-container *ikPermission="''; only: ['Guest']">
    <a class="login-btn" ik-button routerLink="/auth/login">{{ 'header.logInButton.text' | translate }}</a>
    <a class="signup-btn" ik-button routerLink="/auth/signup">{{ 'common.signUp' | translate }}</a>
  </ng-container>
</ng-template>
<ng-container *ikPermission="''; only: ['Owner']">
  <div class="payment-error-alert" *ngIf="hasPendingPayment$ | async">
    {{ 'header.paymentErrorAlert.text' | translate }} <a href="/company-profile/action">{{ 'header.paymentErrorAlert.link' | translate }}</a>
  </div>
</ng-container>
