import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, throwError } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { Report } from 'src/app/reports/state/reports.store';
import { PaginatedData } from 'src/typings/common';
import { ReportResponse } from 'src/typings/reports';

export interface LocalTestsState {
  localTestData: any[];
}

export function createInitialState(): LocalTestsState {
  return {
    localTestData: [],
  };
}

function isNotEmpty(value: any) {
  return value !== null && value !== undefined && value !== '';
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'localTestsState' })
export class LocalTestStore extends Store<LocalTestsState> {
  private localTestStorageName = 'localTestStorage';
  private localReportStorage = 'localReportStorage';

  constructor(private translate: TranslateService) {
    super(createInitialState());
    this.update({
      localTestData: this.getLocalTestStorage(),
    });
  }

  getTestById(id) {
    let localTest = this.getLocalTests();
    var test = localTest.find((test) => test.id === id);
    if (test && test.id) {
      return of(test);
    }
  }

  checkDuplicate(data: any) {
    let localTest = this.getLocalTests();

    const foundTest = localTest.find((test) => {
      return (
        test.test_name === data.test_name &&
        test.equipment_model === data.equipment_model &&
        test.equipment_number === data.equipment_number
      );
    });

    if (foundTest) {
      // Not duplicate.
      return foundTest;
    } else {
      // duplicate.
      return null;
    }
  }

  createTestFromEquipment(test: any, user: any): Observable<Object> {
    let localTest = this.getLocalTests();
    test = this.buildCreateTestData(test, user);
    const foundTest = this.checkDuplicate(test);

    if (!foundTest) {
      localTest.push(test);
      this.saveLocalTest(localTest);
      return of(test);
    } else {
      return of(foundTest);
    }
  }

  createTest(test: any, user: any): Observable<Object> {
    let localTest = this.getLocalTests();
    test = this.buildCreateTestData(test, user);

    if (!this.checkDuplicate(test)) {
      localTest.push(test);
      this.saveLocalTest(localTest);
    }

    return of(Object.assign({}, { localTestData: localTest }));
  }

  updateTest(id: number, updatedObj: any): Observable<Object> {
    return of(updatedObj).pipe(
      map((data: any) => {
        let localTest = this.getLocalTests();
        var index = localTest.findIndex((test) => test.id === id);

        if (index > -1 && localTest && localTest.length) {
          let testData = localTest[index];

          // remove deleted step from steps_attributes.
          if (data.steps_attributes && data.steps_attributes.length > 0) {
            data.steps_attributes = data.steps_attributes.filter((step) => {
              return !step._destroy;
            });

            // remove old steps_attributes data
            if (testData.steps_attributes && testData.steps_attributes.length > 0) {
              delete testData.steps_attributes;
            }
          }

          localTest[index] = { ...testData, ...data };
          this.saveLocalTest(localTest);
        }

        return data;
      })
    );
  }

  deleteTest(id: number) {
    let localTest = this.getLocalTests();

    var removeIndex = localTest.findIndex((test) => test.id === id);
    if (removeIndex > -1) {
      localTest.splice(removeIndex, 1);
    }

    this.saveLocalTest(localTest);
    return of({ localTestData: localTest });
  }

  getId() {
    this.sleep(15);
    return new Date().valueOf();
  }

  getTestByDutModel(params) {
    let localTest = this.getLocalTests();
    var test = localTest.find((test) => {
      if (params.model && test.dut_models && test.dut_models.length > 0) {
        const dut_models = test.dut_models.map((model) => model.toLowerCase());
        return dut_models.includes(params.model.toLowerCase());
      } else {
        return false;
      }
    });
    if (test && test.id) {
      return of(test);
    } else {
      var error = { status: 404, error: this.translate.instant('common.testNotFound') };
      return throwError(error);
    }
  }

  sleep(milliseconds) {
    const date = Date.now();
    let currentDate = null;
    do {
      currentDate = Date.now();
    } while (currentDate - date < milliseconds);
  }

  saveLocalTest(localTest: any) {
    this.setLocalTestStorage(localTest);
    this.update({
      localTestData: localTest,
    });
  }

  reset(): void {
    this.setLocalTestStorage([]);
    this.setLocalReportStorage([]);
    this.update({
      localTestData: [],
    });
  }

  // for transform read-only object to mutable.
  getLocalTests(): any[] {
    let data = this.getValue().localTestData;
    const myClonedArray = [];

    if (data && data.length > 0) {
      data.forEach((val) => {
        myClonedArray.push(Object.assign({}, val));
      });
    }

    return myClonedArray;
  }

  buildCreateTestData(data: any, user: any) {
    let testId = this.getId();

    data.steps_attributes = data.steps_attributes.map((step) => {
      let stepId = this.getId();

      if (step.hasOwnProperty('parameters')) {
        delete step.parameters;
      }

      return {
        ...step,
        id: stepId,
        test_id: testId,
        step: step.step,
        step_type: step.step_type,
        description: isNotEmpty(step.description) ? step.description : null,
        step_binding: isNotEmpty(step.step_binding) ? step.step_binding : null,
        scanner_setup: isNotEmpty(step.scanner_setup) ? step.scanner_setup : null,
        plc_config: isNotEmpty(step.plc_config) ? step.plc_config : null,
        expt_result: isNotEmpty(step.expt_result) ? step.expt_result : null,
        parameters: {
          ...step,
          id: stepId,
          scanner_setup: isNotEmpty(step.scanner_setup) ? step.scanner_setup : undefined,
          plc_config: isNotEmpty(step.plc_config) ? step.plc_config : undefined,
        },
      };
    });

    data = {
      ...data,
      id: testId,
      created_at: new Date(),
      created_by: user,
    };

    return data;
  }

  buildUpdateTestData(updatedObj: any) {
    // remove deleted step from steps_attributes.
    if (updatedObj.steps_attributes && updatedObj.steps_attributes.length > 0) {
      updatedObj.steps_attributes = updatedObj.steps_attributes.filter((step) => {
        return !step._destroy;
      });
    }
    return updatedObj;
  }

  getLocalReport(): ReportResponse[] {
    return this.getLocalReportStorage();
  }

  getReportById(id): Observable<ReportResponse> {
    let reportData = this.getLocalReportStorage();
    var report = reportData.find((report) => report.id == id);
    return of(report);
  }

  getLocalReportForPDF(report_number: any): Observable<any> {
    let reportData = this.getLocalReportStorage();
    var report = reportData.filter((report) => report_number.includes(report.report_number.toString()));
    return of({ data: [].concat(report) });
  }

  saveReport(report: ReportResponse) {
    let reportData = this.getLocalReportStorage();
    reportData.push(report);
    this.setLocalReportStorage(reportData);
    return report;
  }

  private getLocalReportStorage(): ReportResponse[] {
    const reportData = localStorage.getItem(this.localReportStorage);
    if (reportData && reportData !== 'undefined') {
      const data = JSON.parse(reportData);
      const myClonedArray = [];

      if (data && data.length > 0) {
        data.forEach((val) => {
          myClonedArray.push(Object.assign({}, val));
        });
      }

      return myClonedArray;
    }

    return [];
  }

  private setLocalReportStorage(localTest: any): any {
    localStorage.setItem(this.localReportStorage, JSON.stringify(localTest));
  }

  private getLocalTestStorage(): any {
    const testData = localStorage.getItem(this.localTestStorageName);
    if (testData && testData !== 'undefined') {
      const data = JSON.parse(testData);
      const myClonedArray = [];

      if (data && data.length > 0) {
        data.forEach((val) => {
          myClonedArray.push(Object.assign({}, val));
        });
      }

      return myClonedArray;
    }

    return [];
  }

  private setLocalTestStorage(localTest: any): any {
    localStorage.setItem(this.localTestStorageName, JSON.stringify(localTest));
  }
}
