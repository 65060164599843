<div *ngIf="!connected && !activeEquipment" class="soft-not-found">
  <div [innerHtml]="'header.equipmentIndicator.instrumentSoftwareNotDetected' | translate" class="text"></div>
  <a
    *ngIf="!appConfig.isOfflineApp"
    [onboardingText]="downloadText"
    [width]="300"
    href=""
    id="headerDownloadButton"
    ik-button
    ikOnboarding="headerDownloadButton"
    routerLink="/download">{{ 'header.equipmentIndicator.downloadPageButton.text' | translate }}</a
  >
</div>
<div *ngIf="connected && !activeEquipment && equipmentsCount === 0" class="no-equipment-added">
  <div class="text">{{ 'header.equipmentIndicator.noInstrumentAddedText' | translate }}</div>
</div>
<div *ngIf="connected && !activeEquipment && equipmentsCount !== 0" class="no-equipment-connected">
  <div class="indicator"></div>
  <a href="" routerLink="/equipment">{{ 'header.equipmentIndicator.noInstrumentConnectedLink.text' | translate }}</a>
</div>
<a *ngIf="activeEquipment" class="equipment-connected" routerLink="/equipment/test">
  <div class="indicator"></div>
  <div class="text">{{ activeEquipment?.model }} {{ activeEquipment?.model_number }}</div>
</a>
<a
  (click)="resetTips($event)"
  onboardingText="{{ 'header.equipmentIndicator.showTipsButton.onboardingText' | translate }}"
  href=""
  ik-button="secondary"
  ikOnboarding="headerQuestionMark"
>{{ 'header.equipmentIndicator.showTipsButton.text' | translate }}</a>

<ng-template #downloadText>
  {{ 'header.equipmentIndicator.downloadPageButton.onboardingText' | translate }}
  <br />
  <img
    alt=""
    class="mt-1"
    height="32"
    src="/assets/images/icons/favicon-96x96.png"
    width="32" />
</ng-template>
