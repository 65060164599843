import { ObserversModule } from '@angular/cdk/observers';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { IkError } from './form-field/error';
import { FormFieldComponent } from './form-field/form-field.component';
import { IkHint } from './form-field/hint';
import { IkLabel } from './form-field/label';
import { IkInput } from './input/input.directive';
import { OptionComponent } from './option/option.component';
import { SelectTriggerDirective } from './select/select-trigger';
import { IkSelect, MAT_SELECT_SCROLL_STRATEGY_PROVIDER } from './select/select.component';

const EXPORTABLE = [
  FormFieldComponent,
  IkHint,
  IkError,
  IkLabel,
  IkInput,
  IkSelect,
  OptionComponent,
  SelectTriggerDirective,
  CheckboxComponent,
];

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, OverlayModule, ObserversModule],
  declarations: [EXPORTABLE],
  providers: [MAT_SELECT_SCROLL_STRATEGY_PROVIDER],
  exports: [EXPORTABLE, ReactiveFormsModule],
})
export class IkFormsModule {}
