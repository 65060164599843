import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedData } from '@typings/common';
import { CSVData, ReportResponse } from '@typings/reports';
import { Report } from '@app/reports/state/reports.store';

/**
 * Import and use this `BaseReportsService` whenever you need a component to use
 * either `ReportsService` from the 'ikonix-front' app (website) or `OfflineReportsService`
 * from the 'offline-app' depending on which app is running.
 *
 * By using `provide` and `useClass` in the provides of `app.module.ts` and `offline.module.ts`
 * Angular's DI system will provide the indicated implementation.
 *
 * Both `ReportsService` and `OfflineReportsService` extends from `BaseReportsService`.
 */
export abstract class BaseReportsService {
  abstract getById(id: any): Observable<ReportResponse>;
  abstract getByReportNumber(ids: number[] | string[]): Observable<{ data: ReportResponse[] }>;
  abstract getReports(): Observable<PaginatedData<Report>>;
  abstract getPaginatedReports(params: HttpParams): Observable<PaginatedData<Report>>;
  abstract storeReport(dut?: any): Observable<Object>;
  abstract downloadCSV(reportIds: any[], data: any): Observable<CSVData>;
  abstract generateCSVFile(filtered): CSVData;
  abstract uploadReport(report: FormData): Observable<Object>;
  abstract print(config: any): Observable<Blob>;
  abstract exportFilteredCSV(filter: any);
  abstract exporting: boolean;
}
