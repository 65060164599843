import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SocketService } from './socket.service';

@Injectable({
  providedIn: 'root'
})
export class DesktopUpdateService {
  updateInfo = null;
  openModal$ = new BehaviorSubject(false);
  downloading$ = new BehaviorSubject(false);
  progress$ = new BehaviorSubject(null);
  downloaded$ = new BehaviorSubject(false);

  constructor(private socket: SocketService) {}

  init() {
    this.socket.on(['disconnect']).subscribe(() => {
      this.cancel();
    });
    this.socket.on(['update-available']).subscribe(info => {
      this.updateInfo = info;
      this.openModal$.next(true);
      this.downloaded$.next(false);
      this.progress$.next(null);
    });
    this.socket.on(['download-progress']).subscribe(info => {
      this.progress$.next(info);
    });
    this.socket.on(['update-downloaded']).subscribe(info => {
      this.downloaded$.next(true);
    });
  }

  cancel() {
    this.updateInfo = null;
    this.openModal$.next(false);
    this.progress$.next(null);
    this.downloaded$.next(false);
    this.downloading$.next(false);
  }

  download() {
    this.socket.emit('update-app');
    this.downloading$.next(true);
  }

  apply() {
    this.socket.emit('reload-app');
  }
}
