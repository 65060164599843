import { animate, state, style, transition, trigger } from '@angular/animations';

export const transformPanel = trigger('transformPanel', [
  state(
    'void',
    style({
      transform: 'scaleY(0.8)',
      minWidth: '100%',
      opacity: 0
    })
  ),
  state(
    'showing',
    style({
      opacity: 1,
      transform: 'scaleY(1)'
    })
  ),
  state(
    'showing-multiple',
    style({
      opacity: 1,
      transform: 'scaleY(1)'
    })
  ),
  transition('void => *', animate('120ms cubic-bezier(0, 0, 0.2, 1)')),
  transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))
]);

export const fadeIn = trigger('fadeIn', [
  transition('void => *', [
    style({
      transform: 'scaleY(0.6)',
      minWidth: '100%',
      opacity: 0
    }),
    animate(
      '120ms cubic-bezier(0, 0, 0.2, 1)',
      style({
        opacity: 1,
        transform: 'scaleY(1)'
      })
    )
  ]),
  transition('* => void', animate('100ms 25ms linear', style({ opacity: 0 })))
]);
