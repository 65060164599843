import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SnackbarActions, SnackbarOpen } from './actions';
import { CustomSnackbarComponent } from '../../shared/custom-snackbar/custom-snackbar.component';

@Injectable()
export class SnackbarEffects {
  closeSnackbar: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType(SnackbarActions.Close),
        tap(() => this.matSnackBar.dismiss())
      ),
    { dispatch: false }
  );

  showSnackbar: Observable<any> = createEffect(
    () =>
      this.actions.pipe(
        ofType<SnackbarOpen>(SnackbarActions.Open),
        map((action: SnackbarOpen) => action.payload),
        tap((payload) =>
          this.matSnackBar.openFromComponent(CustomSnackbarComponent, {
            data: payload.message,
            duration: 5000,
            ...payload.config,
          })
        )
      ),
    { dispatch: false }
  );

  constructor(private actions: Actions, private matSnackBar: MatSnackBar) {}
}
