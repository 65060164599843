import { Injectable } from '@angular/core';
import { BaseApi } from '@ik/shared-data/base.api';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class CloudApi extends BaseApi {
  base = '/api/v1';

  /** Auth **/
  auth = `${this.base}/auth`;
  login = `${this.base}/login`;
  signup = `${this.base}/signup`;
  logout = `${this.base}/logout`;
  resetPassword = `${this.base}/password`;
  resetPasswordCheck = `${this.base}/password/verify`;

  equipments = `${this.base}/equipments`;
  reports = `${this.base}/reports`;
  reportTemplates = `${this.base}/report-templates`;
  reportLogo = `${this.base}/logo`;
  users = `${this.base}/users`;
  invitation = `${this.base}/invitation`;
  invitationVerify = `${this.base}/invitation/verify`;
  profile = `${this.base}/profile`;
  confirmation = `${this.base}/confirmation`;
  companies = `${this.base}/companies`;
  testFiles = `${this.base}/tests`;
  company = `${this.base}/company`;
  paymentHistory = `${this.base}/payment_history`;
  subscription = `${this.base}/subscription`;
  attachCard = `${this.subscription}/attach-card`;

  adminUsers = `${this.base}/admin/users`;
  adminCompanies = `${this.base}/admin/companies`;
  adminStatistic = `${this.base}/admin/statistic`;
  statistic = `${this.base}/statistic`;

  scannerParameter = '/scanner_parameter';
  userGuide = '/user-guide/WithStand - User Guide.pdf';

  conversion = environment.conversionUrl;
  testFile = `${this.conversion}/api/TestFile`;
}
