import * as TableReducer from '../table-store/reducers';
import * as TableSelectors from '../table-store/selectors';

const localTestsTableName = 'LocalTestsTable';
export const localTestsTable = TableReducer.reducerFactory(localTestsTableName);

export function localTestsTableReducer(state, action) {
  return localTestsTable.reducer(state, action);
}

export const localTestsTableSelectors = TableSelectors.selectorsFactory('localTests', localTestsTableName);
