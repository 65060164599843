import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { PermissionService } from '../services/permission.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private permissionService: PermissionService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    const redirectTo = next.data.redirectTo || '/auth/login';

    const only = next.data.only;
    let except = next.data.except;
    if (!only && !except) {
      except = ['Guest'];
    }

    const tree = this.router.parseUrl(redirectTo);
    return combineLatest([this.permissionService.checkPermission(only, except), this.permissionService.role$]).pipe(
      map(([result, role]) => {
        if (role === 'SuperAdmin' && !result) {
          return this.router.parseUrl('/admin');
        }
        if (result) {
          return true;
        }
        return tree;
      }),
      take(1)
    );
  }
}
