import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { CloudApi } from '@app/helpers/cloud.api';
import { adminUsersTableSelectors } from '../root-store/admin-users-store/admin-users-table.ngrx';

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {
  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private store: Store<any>
  ) { }

  getUsers() {
    return combineLatest([
      this.store.pipe(select(adminUsersTableSelectors.selectPage)),
      this.store.pipe(select(adminUsersTableSelectors.selectFilters)),
      this.store.pipe(select(adminUsersTableSelectors.selectSort))
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters).reduce(function(result, key) {
          const value = filters[key] && filters[key].toString().trim();
          if (value) {
            result[key] = value;
          }
          return result;
        }, {});
        const params = new HttpParams({
          fromObject: {
            ...preparedFilters,
            page: page.toString(),
            order: order || 'first_name'
          }
        });
        return this.http.get(this.cloudApi.adminUsers, { params });
      })
    );
  }

  remove(id) {
    return this.http.delete(`${this.cloudApi.adminUsers}/${id}`);
  }
}
