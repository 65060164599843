import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { TestsService } from '../../services/tests.service';
import { testsTable } from './tests-table.ngrx';
import { MainEquipmentQuery } from '../../state/main-equipment.query';

@Injectable()
export class TestsTableEffects {

  constructor(
    private actions$: Actions,
    private testsService: TestsService,
    private mainEquipmentQuery: MainEquipmentQuery
  ) { }

  onUpdateActive$ = createEffect(() =>
    this.mainEquipmentQuery.tests$.pipe(
      debounceTime(500),
      map(() => new testsTable.actions.LoadData())
    )
  );

  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testsTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.testsService.getAllTests()),
      map(res => {
        return new testsTable.actions.LoadDataSuccess(res);
      })
    )
  );

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testsTable.actionTypes.changeSort),
      map(() => {
        return new testsTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testsTable.actionTypes.changePage),
      map(() => {
        return new testsTable.actions.LoadData();
      })
    )
  );

  filtersChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(testsTable.actionTypes.changeFilters),
      map(() => {
        return new testsTable.actions.LoadData();
      })
    )
  );
}
