import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { debounceTime, map, switchMap } from 'rxjs/operators';
import { UsersService } from '../../services/users.service';
import { usersTable } from './users-table.ngrx';

@Injectable()
export class UsersTableEffects {
  loadData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersTable.actionTypes.loadData),
      debounceTime(200),
      switchMap(() => this.usersService.getUsers()),
      map(res => {
        return new usersTable.actions.LoadDataSuccess(res);
      })
    )
  );

  sortChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersTable.actionTypes.changeSort),
      map(() => {
        return new usersTable.actions.LoadData();
      })
    )
  );

  pageChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersTable.actionTypes.changePage),
      map(() => {
        return new usersTable.actions.LoadData();
      })
    )
  );

  filtersChange$ = createEffect(() =>
    this.actions$.pipe(
      ofType(usersTable.actionTypes.changeFilters),
      map(() => {
        return new usersTable.actions.LoadData();
      })
    )
  );

  constructor(private actions$: Actions, private usersService: UsersService) {}
}
