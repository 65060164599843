import { Injectable } from '@angular/core';
import { type ElectronAPI } from '../../../../../../electron-app/src/preload';

@Injectable({ providedIn: 'root' })
export class ElectronService implements ElectronAPI {
  private _electronApi: ElectronAPI;

  private get electronApi(): ElectronAPI {
    if (!this._electronApi) {
      this._electronApi = window['electronAPI'];

      if (!this._electronApi) {
        console.error(
          'Opening the native Select Folder dialog is only possible when running the application as an Electron app.'
        );
      }
    }

    return this._electronApi;
  }

  openFolderDialog() {
    return this.electronApi.openFolderDialog();
  }

  generatePDF(data: any) {
    return this.electronApi.generatePDF(data);
  }

  openFile(path: string) {
    return this.electronApi.openFile(path);
  }

  openFolder(path: string) {
    return this.electronApi.openFolder(path);
  }
}
