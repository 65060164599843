import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ik-svg-definitions',
  templateUrl: './svg-definitions.component.html',
  styleUrls: ['./svg-definitions.component.scss']
})
export class SvgDefinitionsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
