import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportsTableEffects } from './reports-table-effects.service';
import { reportsTableReducer } from './reports-table.ngrx';

@NgModule({
  imports: [
    CommonModule,

    StoreModule.forFeature('reports', {
      ReportsTable: reportsTableReducer
    }),
    EffectsModule.forFeature([ReportsTableEffects])
  ],
  providers: [ReportsTableEffects]
})
export class ReportsStoreModule {}
