import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { CloudApi } from '@app/helpers/cloud.api';
import { adminCompaniesTableSelectors } from '../root-store/admin-companies-store/admin-companies.ngrx';

@Injectable({
  providedIn: 'root'
})
export class AdminCompaniesService {
  constructor(
    private http: HttpClient,
    private cloudApi: CloudApi,
    private store: Store<any>
  ) { }

  getList() {
    return combineLatest([
      this.store.pipe(select(adminCompaniesTableSelectors.selectPage)),
      this.store.pipe(select(adminCompaniesTableSelectors.selectFilters)),
      this.store.pipe(select(adminCompaniesTableSelectors.selectSort))
    ]).pipe(
      take(1),
      switchMap(([page, filters, order]) => {
        const preparedFilters = Object.keys(filters || {}).reduce(function(result, key) {
          const value = filters[key] && filters[key].toString().trim();
          if (value) {
            result[key] = value;
          }
          return result;
        }, {});
        const params = new HttpParams({
          fromObject: {
            ...preparedFilters,
            page: (page || 1).toString(),
            order: order || 'id'
          }
        });
        return this.http.get(this.cloudApi.adminCompanies, { params }).pipe(
          map((data: any) => {
            if (!data.data) {
              return {
                data,
                meta: {
                  pagination: {
                    per_page: data.length,
                    page: 1,
                    total_count: data.length,
                    limit_value: data.length
                  }
                }
              };
            }
            return data;
          })
        );
      })
    );
  }

  getAll(page = 1, prevData = []) {
    return this.http
      .get<any>(this.cloudApi.adminCompanies, { params: { page: page.toString() } })
      .pipe(
        switchMap(res => {
          prevData = [...prevData, ...res.data];
          if (res.meta.pagination.per_page !== res.meta.pagination.limit_value) {
            return of(prevData);
          }
          return this.getAll(page + 1, prevData);
        })
      );
  }

  remove(id) {
    return this.http.delete(`${this.cloudApi.adminCompanies}/${id}`);
  }

  public getById(id: any) {
    return this.http.get<any>(`${this.cloudApi.adminCompanies}/${id}`);
  }
}
