import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MarkdownModule } from 'ngx-markdown';
import { IkButtonModule } from '../button/ik-button.module';
import { ChangeLogComponent } from './change-log/change-log.component';

@NgModule({
  imports: [CommonModule, IkButtonModule, MarkdownModule.forChild(), TranslateModule],
  declarations: [ChangeLogComponent],
  exports: [ChangeLogComponent],
})
export class ChangeLogModule {}
